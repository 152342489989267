.heading {
  margin-top: 50px;
  margin-left: 50px;
}

.tousfavoris {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  float: right;
  outline: none;
  border: none;
  text-decoration: none;
  color: black;
  font-size: 14px;
  width: 200px;
  /* margin-bottom: 80px; */
}

.tousproduits {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  float: right;
  outline: none;
  border: none;
  text-decoration: none;
  color: black;
  font-size: 14px;
  width: 200px;
}

.tousproduits:hover {
  text-decoration: none;
  cursor: pointer;
  color: var(--global-color-2);
}

.homepage-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.homepage-subcontainer {
  max-width: 1500px;
  width: 100%;
}

.homepage-headingtxt {
  vertical-align: middle;
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: 600;
  white-space: nowrap;
}

.homepage-buttonscontainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
}
