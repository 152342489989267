.infotable {
  margin-bottom: 30px;
  width: 100%;
}

.inforow0 {
  background-color: rgb(242, 246, 248);
}

.inforow1 {
  background-color: white;
}

.inforowelement1 {
  font-size: 13px;
  width: 40%;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  line-height: initial;
  font-weight: 600;
}

.inforowelement2 {
  font-size: 13px;
  height: 50px;
  width: 60%;
  text-align: center;
  vertical-align: middle;
  line-height: initial;
  font-weight: 600;
}

.productpage-path-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.productpage-path {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 28px 0px;
  width: 90%;
  max-width: 1500px;
}

.productpage-loader-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
}

.productpage-loader-msg {
  margin-bottom: 20px;
}
