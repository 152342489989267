.CONTAINER {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 50px;
  margin-right: 50px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 700px) {
  .CONTAINER {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 50px;
    margin-right: 50px;
    padding-bottom: 20px;
  }
}

.slidercontainer {
  display: flex;
  justify-content: center;
}

.awesomeslider {
  margin-top: 1px;
  /* height: 600px; */
}

.sliderinfoa {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.sliderinfobtn {
  position: absolute;
  right: 10%;
  bottom: 20%;
  color: white;
  width: 18%;
  height: 8%;
  background-color: black;
  border-color: black;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  display: flex;
}

.sliderinfobtn:hover {
  cursor: pointer;
}

.sliderinfobtn:focus {
  outline: none;
}

@media only screen and (max-width: 850px) {
  .sliderinfobtntxt {
    font-size: 12px;
    white-space: nowrap;
  }

  .sliderinfobtn {
    height: 8%;
    width: 20%;
  }
}

@media only screen and (min-width: 850px) {
  .sliderinfobtntxt {
    font-size: 12px;
  }
}

.misesajourcontainer {
  display: flex;
  flex-direction: column;
}

.misesajourtitle {
  margin-top: 50px;
  margin-bottom: 15px;
  text-transform: uppercase;
  margin-left: 50px;
}

.blocvignettecontent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 50px;
  /* height: 330px;
  width: 330px; */
}

.blocvignettecontenta {
  height: 100%;
  width: 100%;
}

.blocvignettecontenta:hover {
  text-decoration: none;
}

.blocvignettecontentimage {
  width: 100%;
  height: auto;
  /* max-width: 300px; */
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1200px) {
  .blocvignettecontenttitle {
    color: black;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
  }
}

@media only screen and (min-width: 1200px) {
  .blocvignettecontenttitle {
    color: black;
    font-size: 13px;
    line-height: 1;
    font-weight: 600;
  }
}

.awssld__bullets {
  z-index: 2;
  bottom: 0px;
}

.awssld__bullets button {
  width: 8px;
  height: 8px;
  background-color: white;
  opacity: 0.5;
}

.awssld__bullets .awssld__bullets--active {
  background-color: white;
  opacity: 1;
}

.awesomeslider .awssld__timer {
  opacity: 0;
  pointer-events: none;
}

.awssld {
  --loader-bar-color: transparent;
}
