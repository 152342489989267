.medias-innerview {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.medias-outercontainer {
  width: 100%;
}

.medias-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.medias-subcontainer {
  max-width: 1300px;
  width: 90%;
}
