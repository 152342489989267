.dlt-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(242, 246, 248); */
  min-height: 400px;
  max-height: 600px;
  /* border: 1px rgb(230, 236, 243) solid; */
  border-left: none;
  border-right: none;
  font-size: 13px;
  color: #232323;
  font-weight: 600;
}

.dlt-title-container {
  width: 512px;
  max-width: 90%;
  display: flex;
  align-items: flex-end;
  min-height: 80px;
}

.dlt-skus {
  line-height: 80px;
  letter-spacing: 3px;
  color: #232323;
  font-size: 12px;
}

.dlt-subroot {
  overflow: hidden;
  height: 60%;
  width: 512px;
  max-width: 90%;
  margin-bottom: 75px;
  z-index: 1;
}

.dlt-subroot:hover {
  overflow: auto;
}

.dlt-subroot::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}
.dlt-subroot::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.dlt-row-link {
  color: black;
}

.dlt-row-link:hover {
  text-decoration: none;
}

.dlt-row1 {
  text-align: center;
  background-color: rgb(242, 246, 248);
  height: auto;
}

.dlt-row2 {
  text-align: center;
  background-color: white;
  height: auto;
}

.dlt-header {
  text-align: center;
  background-color: white;
  height: auto;
}

.dlt-hitem {
  padding: 0px 20px;
  white-space: nowrap;
  position: sticky;
  top: 0px;
  background-color: white;
  color: #6b7c8d;
  vertical-align: middle;
  font-size: 13px;
  letter-spacing: -0.5px;
  font-weight: 600;
}

.dlt-item {
  padding: 5px 20px;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 13px;
  letter-spacing: -0.5px;
  font-weight: 600;
}

.dlt-loader {
  justify-self: center;
  align-self: center;
}
