.cart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.cart-subcontainer {
  max-width: 1500px;
  width: 85%;
}

.cart-downloadall {
  width: 150px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  border-radius: 20px;
  border-width: 0px;
  background-color: rgb(39, 116, 169);
}

.cart-downloadall:focus {
  outline: none;
}

.cart-title {
  text-align: center;
  margin: 20px;
  font-size: 20px;
}

.cart-itemcontainer::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.cart-itemcontainer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.cart-noresult-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.cart-noresult-subcontainer {
  background-color: rgb(230, 233, 237);
  padding: 4rem 2rem;
  color: black;
  max-width: 1500px;
  width: 85%;
}

.cart-noresult-h1 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.cart-noresult-p {
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 1rem;
}

.cart-noresult-btn {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.cart-noresult-btn:hover {
  background-color: rgb(165, 181, 198);
  text-decoration: none;
  color: #fff;
}
