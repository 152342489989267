/* Dropdown Button */

.ld-chevrondown {
  margin-left: 20px;
}

.ld-dropbtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  padding: 0.25rem 2rem 0.25rem 2rem;
}

.ld-dropbtn:focus {
  outline: none;
}

.ld-dropbtn:hover {
  cursor: pointer;
}

/* Styles pour le dropdown de sélection de langue */

.ld-dropdown-content {
  display: none;
  padding: 12px 20px;
  width: 180px !important;
}

.ld-dropdown:hover .ld-dropdown-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  border-radius: 8px;
  width: 150px;
}

.ld-dropdown-content p {
  color: #444;
  border-bottom: 1px solid #ccc;
  font-size: 12px;
  /* margin-left: 6px;
  margin-right: 6px; */
  line-height: 20px;
  margin-bottom: 5px;
}

.ld-dropdown-content a:hover {
  color: black;
}

.ld-dropdown-element {
  display: flex;
  flex-direction: row;
}

.ld-dropdown-element-name {
  text-decoration: none;
  color: lightgrey;
  font-size: 14px;
  margin-left: 4px;
  width: 120px;
  margin-bottom: -8px;
  cursor: pointer;
}

.ld-check {
  margin-top: 10px;
  color: black;
  opacity: 0.3;
}

.langs .ld-dropbtn{
  padding: 0px;
}

.langs.ld-dropdown{
  min-width: 140px
}
