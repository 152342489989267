/* .dl-arrow {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.dl-arrow-icon {
  font-size: 40px;
  color: black;
  cursor: pointer;
}

.dl-carousel {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  z-index: 999;
}

.BrainhubCarousel__trackContainer {
  /* z-index: -1; */
}

.dl-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.dl-subcontainer {
  width: 1024px;
  max-width: 90%;
}

.dl-element-link:hover {
  text-decoration: none;
}

.dl-element-container {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.7)
  ); */
  background-size: cover;
  color: white;
  padding: 20px;
}

.dl-element-img {
  width: 372;
  height: 220px;
  /* border: solid 1px black; */
  box-shadow: 0 0 14px 0 rgba(206, 206, 206, 1);
  margin-bottom: 10px;
}

.dl-element-p {
  font-size: 12px;
  line-height: 14px;
  width: 80%;
  color: black;
}

.BrainhubCarousel__arrow--disable {
  visibility: hidden;
}
