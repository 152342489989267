.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footer-subcontainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  max-width: 1500px;
  width: 90%;
}

.footer {
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
  color: #c3c3c3;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 3px;
  font-size: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  /* margin-right: 50px; */
}

.footer span {
  margin-right: 10px;
}

.imgFooter {
  background-image: url('../../images/logoQuable.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 20px;
  margin-left: 1%;
}
