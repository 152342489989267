.parametres-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.parametres-subcontainer {
  width: 85%;
  max-width: 1500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.parametres-leftmenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

h1 {
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

.parametres-leftmenu-element {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  margin-left: 20px;
}

.parametres-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 100px;
}

.parametres-right-firstrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#parametres-pagesize {
  font-size: 12px;
  height: 30px;
  border: 1px solid grey;
}

#filter-text-box {
  font-size: 12px;
  height: 30px;
}

.parametres-righttxt {
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
}

.parametres-right-firstrow-firsttxt {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.parametres-table-btn1 {
  margin-right: 20px;
  color: rgb(39, 116, 169);
}

.parametres-table-btn2 {
  margin-right: 20px;
  color: red;
}

.parametres-icon {
  margin-right: 5px;
}

.parametre-popup {
  width: 250px;
  border-radius: 25px;
  border: 2px solid black;
}

/** Gestion du tableau de recherche **/

.grid-search-box {
  height: 35px;
  width: 100%;
  border-width: 1px;
  border-radius: 20px;
  padding: 0.25rem 3rem 0.25rem 3rem;
  background: url(../../images/icons/search.png) no-repeat 12px center;
  background-color: white;
  outline: medium none;
  font-size: 14px;
  vertical-align: middle;
}

.row_bottom_margin {
  margin-bottom: 15px;
}

.page-size-select {
  width: 45px;
  outline: none;
  box-shadow: none;
  margin-right: 10px;
  margin-left: 10px;
}
.page-size-select:focus {
  width: 35px;
  outline: none;
  box-shadow: none;
}

.page-size-select-row {
  text-align: right;
  margin: 10px;
}

.action-button {
  cursor: pointer;
}

@media only screen and (max-width: 850px) {
  .parametres-subcontainer {
    width: 85%;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .parametres-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 0px;
    margin-top: 50px;
  }
}

.parametres-prismic {
  color: #516677;
  font-size: 14px;
  font-weight: 600;
}

.parametres-prismic:hover {
  text-decoration: none;
  cursor: pointer;
  color: #516677;
}
