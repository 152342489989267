.vm-popup-content {
  /* width: 1000px; */
}

.vm-container {
  background-color: rgb(32, 32, 32);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.vm-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 15px;
  min-height: 75px;
}

.vm-info-subcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 15px;
}

.vm-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 768px) {
  .vm-media-name {
    width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.vm-download-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(53, 53, 53);
  border-width: 0px;
  color: white;
  margin-right: 15px;
  outline: none;
  border-radius: 2px;
  padding: 5px 15px 5px 15px;
}

.vm-download-btn:focus {
  outline: none;
}

.vm-add-btn {
  background-color: rgb(64, 145, 213);
  border-width: 0px;
  color: white;
  outline: none;
  border-radius: 2px;
  padding: 5px 15px 5px 15px;
}

.vm-add-btn:focus {
  outline: none;
}

.definition-btn {
  display: flex;
  flex-direction: 'row';
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 30px;
  height: 25px;
  border-radius: 2px;
  background-color: rgb(43, 43, 43);
  color: white;
}

.definition-btn-active {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 30px;
  height: 25px;
  border-radius: 2px;
  background-color: white;
  color: rgb(43, 43, 43);
}

.vm-image {
  width: 400px;
  height: 400px;
  object-fit: contain;
}

.vm-thumbnail {
  width: 50px;
}

.vm-left-arrow:hover {
  cursor: pointer;
}

.vm-left-arrow {
  margin-left: 5px;
  font-size: 50px;
  color: white;
}

.vm-right-arrow:hover {
  cursor: pointer;
}

.vm-right-arrow {
  margin-right: 5px;
  font-size: 50px;
  color: white;
}

.vm-close {
  align-self: flex-end;
  margin-right: 5px;
  font-size: 30px;
  color: white;
}

.vm-close:hover {
  opacity: 0.3;
  cursor: pointer;
}

.vm-image-and-carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.vm-carousel-container {
  margin-top: 30px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.vm-mainimage {
  width: 80%;
  height: 526px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.vm-video {
  width: 80%;
  height: 526px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
