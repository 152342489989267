.ci-container {
  width: 100%;
  height: 150px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ci-img {
  background-repeat: no-repeat;
  background-size: contain;
  width: 140px;
  height: 140px;
  background-position: center center;
  margin-left: 30px;
}

.ci-download-btn {
  margin-right: 20px;
  cursor: pointer;
}

.ci-trash {
  cursor: pointer;
  margin-right: 20px;
  font-size: 14px;
}
