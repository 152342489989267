.sf-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.sf-element {
  margin-top: 10px;
  margin-right: 20px;
}

.sf-date-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sf-heading {
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 700;
  color: #b6b6b6;
  font-size: 11px;
  line-height: 11px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.sf-subheading {
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 700;
  color: #b6b6b6;
  font-size: 8px;
  line-height: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.react-datepicker-wrapper {
  width: 125px;
}

.sf-submit-btn {
  margin-top: 30px;
  height: 40px;
  width: 150px;
  border: none;
  color: white;
  background-color: black;
  border-color: black;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  display: flex;
}

.react-datepicker__input-container {
  font-size: 14px;
}

/* MULTI SELECT */

.dropdown {
  width: 225px;
}

.dropdown-heading {
  height: 40px !important;
}

.dropdown-heading-value {
  font-size: 14px;
  line-height: 38px !important;
}

.dropdown-content {
  font-size: 14px;
}

.item-renderer span {
  vertical-align: bottom !important;
}
