.mic-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
}

.mic-arrow {
  color: rgb(100, 100, 100);
  font-size: 50px;
  cursor: pointer;
}

.mic-item {
  margin-right: 10px;
  margin-left: 10px;
  width: 100%;
  height: auto;
  max-width: 300px;
}

.mic-item-background {
  background-size: cover;
}

.mic-item-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.mic-item-button {
  margin-top: 35px;
  margin-right: 10px;
  color: white;
}

.mic-item-button:hover {
  cursor: pointer;
}

.mic-item-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(72, 68, 68);
  opacity: 0.9;
  font-size: 12px;
  margin-top: 60px;
  color: white;
}

.mic-item-dates {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mic-item-date1 {
  margin-right: 3px;
}

.mic-item-date1-p {
  margin-right: 5px;
}

.mic-item-date2 {
  margin-right: 3px;
}
