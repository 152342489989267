.pbv-hroot-container {
  display: flex;
  flex-flow: row wrap;
  opacity: 1;
  /* width: 100%; */
  overflow: hidden;
  height: 280px;
}

.pbv-hbutton-subcontainer {
  margin: 0px 20px 38px 0px;
  width: 220px;
}

.pbv-hbutton {
  height: 220px;
  width: 220px;
  border: none;
  outline: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  /* background-color: var(--global-color-3); */
  background-color: white;
  margin-bottom: 11px;
  /* background-image: url('../../images/no-image.png'); */
}

.pbv-hmedia-name {
  width: 220px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
  text-transform: uppercase;
}

.pbv-hsubtitle {
  color: #b6b6b6;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  margin: 0;
}

.pbv-hstar {
  margin-top: 5px;
  margin-right: 5px;
  float: right;
  color: rgb(217, 165, 31);
  font-size: 20;
}

.pbv-hstar:hover {
  color: rgb(196, 33, 70);
}

.pbv-hstarv2 {
  margin-top: 5px;
  margin-right: 5px;
  float: right;
  color: rgb(165, 181, 198);
  font-size: 20;
}

.pbv-hstarv2:hover {
  color: rgb(64, 145, 213);
}

.pbv-root-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  opacity: 1;
  width: 100%;
}

.pbv-linktype-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  /* margin-top: 20px; */
  /* opacity: 1; */
}

.pbv-linktype-heading {
  font-size: 25px;
  color: white;
}

.pbv-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.pbv-button-container-rm {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.pbv-button-subcontainer {
  margin: 0px 10px 38px 10px;
  width: 220px;
}

.pbv-button-subcontainer-rm {
  margin: 0px 18px 38px 18px;
  width: 220px;
}

.pbv-button {
  height: 220px;
  width: 100%;
  border: none;
  outline: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  /* background-color: var(--global-color-3); */
  /* background-color: white; */
  margin-bottom: 11px;
  /* background-image: url('../../images/no-image.png'); */
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.pbv-star {
  margin-top: 5px;
  margin-right: 5px;
  float: right;
  color: rgb(217, 165, 31);
  font-size: 20;
}

.pbv-star:hover {
  color: rgb(196, 33, 70);
}

.pbv-starv2 {
  margin-top: 5px;
  margin-right: 5px;
  float: right;
  color: rgb(165, 181, 198);
  font-size: 20;
  z-index: 999;
}

.pbv-starv2:hover {
  color: rgb(64, 145, 213);
}

.blocCheckIcon {
  font-size: 50px;
  color: white;
}

@keyframes custom-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.75;
  }
}

.pbv-selected {
  width: 100%;
  height: 100%;
  opacity: 0.75 !important;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: custom-fadein;
  animation-duration: 1s;
}

.pbv-btn-selected {
  width: 97%;
  height: 205px;
}

.pbv-media-name {
  width: 220px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 12px;
}

@media only screen and (max-width: 1200px) {
  .pbv-button {
    /* height: 250px;
    width: 250px; */
  }

  .pbv-media-name {
    /* width: 200px; */
  }

  /* .pbv-media-name {
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  } */
}

@media only screen and (max-width: 850px) {
  .pbv-button-subcontainer {
    margin: 0px 15px 38px 15px;
    width: 150px;
  }

  .pbv-button {
    height: 150px;
    width: 150px;
  }

  .pbv-media-name {
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.pbv-subtitle {
  color: #b6b6b6;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  margin: 0;
}

.pbv-linktype-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.pbv-caretdown {
  color: white;
  font-size: 60px;
  margin-left: 10px;
  cursor: pointer;
}

.pbv-favoris-tooltip {
  margin-left: 138px;
}

@media only screen and (max-width: 850px) {
  .pbv-favoris-tooltip {
    margin-left: 70px;
  }
}

.pbv-select-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
}

.pbv-root-container-rm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  opacity: 1;
  width: 100%;
  min-height: 500px;
}

.pbv-raccourcis-container {
  display: none;
  background-color: white;
  padding: 0px 0px 3px 3px;
}

.pbv-button:hover .pbv-raccourcis-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.pbv-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: black;
  font-size: 12px;
  line-height: 12px;
  margin-top: 5px;
}
