.mmic-container {
  margin-top: 30px;
  width: 470px;
  max-width: 75%;
}

.mmic-nextimage:hover {
  cursor: pointer;
}

.mmic-previousimage:hover {
  cursor: pointer;
}

.mmic-arrow {
  cursor: pointer;
  width: 30px;
  height: 46px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mmic-carouselimg-active {
  max-width: 72px;
  max-height: 46px;
}

.mmic-carouselimg {
  max-width: 72px;
  max-height: 46px;
  opacity: 0.5;
}
