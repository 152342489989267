.profileImg {
  height: 40px;
}
.userinfodropdowncontent {
  width: 250px !important;
}

.popupContainer {
}
.popUpRow {
  margin: 0;
}

.popInput {
  border: none;
}
.popInputFull {
  width: 100%;
}

.popupItemPadding {
}

.popupButton {
  width: 100%;
  height: 36px;
  background: none;
  border: 1px solid blue;
  border-radius: 25px;
  color: blue;
  text-align: center;
  font-size: 15px;
  line-height: 15px;
  font-weight: bold;
  outline: none;
}
.savebutton {
  background: #cacaca;
  color: black;
  border: #cacaca;
  font-weight: bold;
}

.popupButtonInactive {
  width: 100%;
  height: 36px;
  background: none;
  border: none;
  border-radius: 25px;
  color: #cacaca;
  text-align: center;
  font-size: 15px;
  line-height: 15px;

  outline: none;
}
.ztoItemLabel {
  font-weight: bold;
}
.popUpHeader {
  font-weight: bold;
}
.closeArrow {
  text-align: right;
  font-weight: bold;
  font-size: 20px;
}

.headerMargin {
  margin-left: 15px;
}

button:focus {
  outline: none;
  box-shadow: none;
}
input:focus {
  outline: none;
  box-shadow: none;
}

select:focus {
  outline: none;
  box-shadow: none;
  border: none;
}
select {
  width: 100%;
  border: none;
}
input {
  width: 100%;
}

textarea {
  width: 100%;
}

/** Modal **/
.ztogroupedrow {
  margin-bottom: 15px;
}
.ztoPopUpCont {
  /* height: 740px;
  position: relative; */
  font-size: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.ztoPopUpCont:hover {
  overflow: auto;
}

.ztoAudiencePopUpCont {
  font-size: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.ztoAudiencePopUpCont:hover {
  overflow: auto;
}

.ztoactionButton {
  /* position: absolute; */
  bottom: 25px;
  text-align: right;
}

.ztomandory {
  color: red;
}

.ztoerrormsg {
  color: red;
  text-align: center;
}

.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.ztoModal > .closepopup {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.ztorow {
  margin-bottom: 20px;
}

.ztoModalTitle {
  margin-bottom: 35px;
}
.saveBtnContainer {
  margin-top: 20px;
}
