.dli-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.dli-subcontainer {
  max-width: 1024px;
  width: 90%;
}
