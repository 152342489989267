.produits-innerview {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.produits-outercontainer {
  width: 100%;
}
.produits-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.produits-subcontainer {
  max-width: 1300px;
  width: 90%;
}
