.navstyle2 {
  color: black;
}

.navstyle2active {
  background-color: rgb(242, 246, 248);
  border-style: solid;
  border-radius: 4px;
  border-color: grey;
  border-width: 1px;
}

.nav-tabs {
  /* height: 33px; */
  line-height: initial;
}

.nav-item {
  color: black;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: black;
  background-color: white;
}

.nav-link {
  color: black;
}

.nav-link:hover {
  color: black;
}

.active {
}

.api-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.api-subcontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.api-subsubcontainer {
  width: 100%;
  max-width: 1024px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 14px;
}

.api-navlink {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.25rem;
  border: 1px solid white;
  white-space: nowrap;
}

.api-tabs {
  font-size: 14px;
  width: 100%;
  /* width: 90%; */
}

.api-fields-container {
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; */
}

.tab-content > .active {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.tab-content {
  width: 100%;
}

.row {
  width: 100%;
}

.tab-pane {
  width: 100%;
}

.api-field1 {
  margin-right: 25px;
  margin-bottom: 25px;
  min-height: 100px;
  min-width: 250px;
  width: 47%;
  background-color: rgb(242, 246, 248);
  border-style: solid;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

.api-field2 {
  margin-bottom: 25px;
  min-height: 100px;
  min-width: 250px;
  width: 47%;
  background-color: rgb(242, 246, 248);
  border-style: solid;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

.api-col {
  max-width: 300px;
  /* margin-right: 30px; */
}

.api-field-h4 {
  letter-spacing: -0.5px;
  margin: 0 0 14px 0;
  color: #6b7c8d;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
}

.api-field-p {
  letter-spacing: -0.5px;
  margin: 0;
  color: #232323;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.25rem;
  min-height: 20px;
}

@media only screen and (max-width: 1024px) {
  .row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
  .tab-content {
    width: 100%;
  }
  .api-field1 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-top: 25px;
  }

  .api-field2 {
    width: 100%;
    margin-bottom: 0px;
    margin-top: 25px;
  }
}
