@media only screen and (min-width: 850px) {
  .bi-container {
    width: 1024px;
    max-width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 48px;
    padding-bottom: 66px;
  }

  .bi-elts-container {
    display: block;
    break-inside: avoid;
    width: 49%;
    margin: 0 0 20px 0;
    padding: 26px 20px;
    background: #f4f7f9;
    border-radius: 4px;
  }
}

@media only screen and (max-width: 850px) {
  .bi-container {
    width: 1024px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 48px;
    padding-bottom: 66px;
  }

  .bi-elts-container {
    display: block;
    break-inside: avoid;
    width: 90%;
    margin: 0 0 20px 0;
    padding: 26px 20px;
    background: #f4f7f9;
    border-radius: 4px;
  }
}

.bi-title {
  letter-spacing: -0.5px;
  margin: 0 0 14px 0;
  color: #6b7c8d;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
}

.bi-content {
  letter-spacing: -0.5px;
  margin: 0;
  color: #232323;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.25rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
