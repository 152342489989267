.produits {
  display: flex;
  flex-flow: row wrap;
  height: 220px;
  overflow: hidden;
}

.lb-item-info-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(72, 68, 68);
  opacity: 0.9;
  font-size: 12px;
  color: white;
  width: 100%;
  height: 66px;
}

.lb-item-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  opacity: 0.9;
  font-size: 12px;
  color: white;
  width: 82%;
}

.lb-item-dates {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.lb-item-date1 {
  margin-right: 3px;
}

.lb-item-date1-p {
  margin-right: 15px;
}

.lb-item-date2 {
  margin-right: 3px;
}

.lb-produit-container {
}

.lb-produit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  background-color: var(--global-color-3);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 220px;
  height: 220px;
  border: none;
  outline: none;
  background-size: 100%;
  cursor: pointer;
  /* margin-left: 10px; */
  margin-right: 20px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 4px solid black;
}

.lb-produit:hover {
  border: none;
}

.lb-produit:hover .lb-item-info-container {
  height: 70px;
}

.lb-link:hover {
  text-decoration: none;
}
