.ic-bloc {
  margin-left: 20px;
  margin-right: 10px;
  color: black;
}

.ic-bloc:hover {
  cursor: pointer;
}

.ic-list {
  color: black;
}

.ic-list:hover {
  cursor: pointer;
}

.ic-bloc-rm {
  margin-left: 20px;
  margin-right: 10px;
  color: white;
}

.ic-bloc-rm:hover {
  cursor: pointer;
}

.ic-list-rm {
  color: white;
}

.ic-list-rm:hover {
  cursor: pointer;
}

.ic-container {
  width: 100%;
}

.ic-subcontainer {
  width: 100%;
}

.ic-subsubcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.ic-switchviewcontainer-container {
  margin-bottom: 20px;
}

.ic-switchviewcontainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

#ic-secondrow-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

input[type='checkbox'] {
  margin-right: 10px;
  width: auto;
}

.ic-label-check {
  display: inline-block;
  padding: 2% 9%;
  cursor: pointer;
  width: 100%;
}

.ic-secondrow-selection-selectionmode {
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(39, 39, 39);
  outline: none;
  border-width: 0px;
  border-radius: 3px;
  color: white;
  /* padding-left: 24px;
  padding-right: 24px; */
  height: 44px;
  font-size: 14px;
  font-weight: 600;
}

.ic-secondrow-showwhat-mediastypes {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 2px;
  margin-right: 20px;
  width: 350px;
  justify-content: center;
}

.ic-secondrow-selection-allselection {
  width: 200px;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(39, 39, 39);
  outline: none;
  border: 1px solid black;
  border-radius: 3px;
  background-color: white;
  color: black;
  margin-left: 5px;
  height: 44px;
}

.ic-secondrow-selection-allselection-hide {
  display: none;
}

.ic-footer-hide {
  display: none;
}

.ic-footerstyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(39, 39, 39);
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  position: fixed;
  bottom: 10px;
  height: 60px;
  width: 1300px;
  max-width: 90%;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  font-size: 14px;
}

@media only screen and (max-width: 850px) {
  .ic-footerstyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(39, 39, 39);
    border-radius: 4px;
    text-align: center;
    vertical-align: middle;
    position: fixed;
    bottom: 10px;
    height: 60px;
    width: 1024px;
    max-width: 90%;
    left: 50%;
    transform: translate(-50%, 0);
    color: white;
    font-size: 14px;
  }
}

.ic-footerstyle-p {
  margin-left: 50px;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.375rem;
}

.ic-footerstyle-b:focus {
  outline: none;
}

.ic-footerstyle-b:hover {
  background-color: var(--global-color-2);
}

.ic-footerstyle-b {
  cursor: pointer;
  margin-right: 20px;
  background: #3d3d3d;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  line-height: 1rem;
  font-size: 1rem;
  padding: 0 30px 0 30px;
  height: 44px;
  line-height: 43px;
}

#ic-check {
  cursor: pointer;
}

.ic-noresult-container {
  background-color: rgb(230, 233, 237);
  width: 100%;
  height: 100%;
  padding: 4rem 2rem;
  color: black;
}

.ic-noresult-h1 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.ic-noresult-p {
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 1rem;
}

.ic-noresult-btn {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ic-noresult-btn:hover {
  background-color: rgb(165, 181, 198);
}

.ic-loader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
}

.ic-view-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

@media only screen and (max-width: 1200px) {
  .ic-subsubcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ic-switchviewcontainer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ic-bloc {
    margin-left: 0px;
    margin-right: 10px;
    color: black;
  }

  .ic-bloc-rm {
    margin-left: 0px;
    margin-right: 10px;
    color: white;
  }

  .ic-secondrow-showwhat-mediastypes {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border-radius: 2px;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 350px;
    justify-content: center;
  }

  .ic-view-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 20px;
  }
}

.ic-itemview {
  width: 100%;
}

.ic-cart-download {
  width: 200px;
  height: 50px;
  background-color: black;
  color: white;
  outline: none;
  border: none;
}

.ic-cart-empty {
  width: 200px;
  height: 50px;
  background-color: #e9e9e9;
  color: black;
  outline: none;
  border: none;
  margin-left: 10px;
}
