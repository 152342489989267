.header-container {
  height: 66px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 999;
  /* background: linear-gradient(
    to right,
    rgba(21, 31, 42, 0.95) 0%,
    rgba(32, 57, 85, 0.95) 100%
  ); */
  background-color: var(--entete);
}

.header-subcontainer {
  width: 90%;
  max-width: 1500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-leftleft {
  display: none;
}

.header-left {
}

.header-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header-bars {
  display: none;
}

.header-logo {
  vertical-align: middle;
  max-width: 172px;
}

.header-center {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header-searchinput {
  max-width: 350px;
  width: 90%;
}

.header-searchinput input {
  height: 35px;
  width: 100%;
  border-width: 0px;
  border-radius: 20px;
  padding: 0.25rem 3rem 0.25rem 3rem;
  background: url(../../images/icons/search.png) no-repeat 12px center;
  background-color: white;
  outline: medium none;
  font-size: 14px;
  vertical-align: middle;
}

.header-parametres {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  /* opacity: 0.3; */
}

.header-parametres:hover {
  color: white;
  opacity: 1;
  text-decoration: none;
}

.header-parametres-icon {
  margin-right: 10px;
  font-size: 20px;
}

.header-parametres-p {
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}

.header-cart {
  margin-left: 30px;
  color: white;
  /* opacity: 0.3; */
}

.header-cart:hover {
  color: white;
  opacity: 1;
}

.header-cart-icon {
  font-size: 20px;
  /* vertical-align: middle; */
}

.header-favoris {
  margin-left: 30px;
  color: white;
  /* opacity: 0.3; */
}

.header-favoris:hover {
  color: white;
  opacity: 1;
}

.header-favoris-star {
  font-size: 20px;
  /* vertical-align: middle; */
}

.divider {
  margin-left: 50px;
  height: 66px;
  border-left: 1px solid white;
  opacity: 0.2;
}

.header-logout-icon {
  margin-right: 10px;
  font-size: 20px;
}

.header-logout-p {
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}

.header-logout {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  padding: 0.5rem 2rem 0.5rem 2rem;
  /* opacity: 0.3; */
  outline: none;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
}

.header-logout:hover {
  color: white;
  opacity: 1;
  cursor: pointer;
  text-decoration: none;
}

.header-logout:focus {
  outline: none;
}

@media only screen and (min-width: 840px) {
  #dropdownheadermenu {
    display: none;
  }

  .header-forsmallscreensize {
    display: none;
  }
}

.header-search {
  display: none;
}

@media only screen and (max-width: 1100px) and (min-width: 850px) {
  .searchIcon {
    color: white;
    /* opacity: 0.3; */
    margin-right: 40px;
  }

  .searchIcon:hover {
    opacity: 1;
  }

  .header-searchinput {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .header-logout {
    margin-right: 0px;
  }

  .header-searchinput {
    display: none;
  }

  .header-search {
    display: block;
    color: white;
    /* opacity: 0.3; */
  }

  .header-search:hover {
    color: white;
    opacity: 1;
  }
}

@media only screen and (max-width: 850px) {
  .header-leftleft {
    display: block;
  }

  .header-bars {
    display: block;
    color: white;
    /* opacity: 0.3; */
    cursor: pointer;
  }

  .header-center {
    display: none;
  }

  .header-bars:hover {
    opacity: 1;
  }

  .header-logo {
    margin: 0px;
  }

  .langs {
    display: none;
  }

  .header-parametres {
    display: none;
  }

  .header-favoris {
    display: none;
  }

  .header-cart {
    display: none;
  }

  .divider {
    display: none;
  }

  .header-logout {
    display: none;
  }

  .header-forsmallscreensize {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 100%;
  }

  #dropdownheadermenu {
    position: fixed;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
    z-index: 999;
    background: transparent;
  }

  .dropdownlangs {
    color: black;
    margin-top: 10px;
  }

  .dropdownlangs:hover {
    margin-bottom: 450px;
  }

  .dropdownoverlay {
    position: fixed;
    top: 322px;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.7;
  }

  .dropdownlangs:hover ~ .dropdownoverlay {
    top: 773px;
  }

  .dropdown-linkitem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 16px;
    border: none;
    padding: 0.5rem 2rem 0.5rem 2rem;
    /* opacity: 0.3; */
    outline: none;
    cursor: pointer;
    width: 100%;
  }

  .dropdown-linkitem:hover {
    color: black;
    opacity: 1;
    text-decoration: none;
    background-color: rgb(242, 246, 248);
  }

  .dropdown-icon {
    margin-right: 5px;
    line-height: 10px;
  }
}
