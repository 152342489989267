.enabledLink {
  color: #b6b6b6;
}

.disabledLink {
  color: var(--global-color-1);
}

.link {
  margin-top: 6px;
  margin-bottom: 6px;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.35rem;
  margin-right: 25px;
}

.link:hover {
  color: var(--global-color-2);
  text-decoration: none;
}

@media only screen and (max-width: 850px) {
  .link {
    margin-top: 6px;
    margin-bottom: 6px;
    text-decoration: none;
    font-weight: 600;
    font-size: 15px;
    line-height: 1.35rem;
    margin-right: 25px;
  }
}
