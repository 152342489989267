.lpb-item-info-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(72, 68, 68);
  opacity: 0.9;
  font-size: 12px;
  color: white;
  width: 100%;
  height: 66px;
}

.lpb-item-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  opacity: 0.9;
  font-size: 12px;
  color: white;
  width: 82%;
}

.lpb-item-title {
  text-align: start;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.lpb-item-dates {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.lpb-item-date1 {
  margin-right: 3px;
}

.lpb-item-date1-p {
  margin-right: 15px;
}

.lpb-item-date2 {
  margin-right: 3px;
}

.lpb-produit-container {
}

.lpb-produit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  background-color: var(--global-color-3);
}

.lpb-produits {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.lpb-produit {
  width: 220px;
  height: 220px;
  border: none;
  outline: none;
  background-size: 100%;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 4px solid black;
}

.lpb-produit:hover {
  border: none;
}

.lpb-produit:hover .lpb-item-info-container {
  height: 70px;
}

.lpb-link:hover {
  text-decoration: none;
}
