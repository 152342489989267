.button_1 {
  width: 225px;
  height: 40px;
  background-color: black;
  color: white;
  font-size: 16px;
  outline: none;
  border-radius: 4px;
}

.button_1:hover {
  color: var(--global-color-2);
  cursor: pointer;
}
