.tvsp-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  width: 100%;
}

.tvsp-path {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 140px;
  font-size: 14px;
  line-height: 18px;
  flex-wrap: wrap;
  width: 100%;
}

@media only screen and (max-width: 850px) {
  .tvsp-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    width: 800%;
  }

  .tvsp-path {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 18px;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-left: 0px;
  }
}

.tvsp-button {
  margin-right: 200px;
}

.tvsp-el {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tvsp-el-button {
  background-color: transparent;
  border-width: 0;
  font-size: 14px;
  white-space: nowrap;
  text-align: center;
}

.tvsp-el-button:focus {
  outline: none;
}

.tvsp-el-arrow {
  margin-left: 5px;
  margin-right: 5px;
  color: black;
}

.tvsp-product-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border-width: 0;
  font-size: 14px;
  white-space: nowrap;
  text-align: center;
  color: black;
  margin-left: 8px;
}

.tvsp-end {
  height: 10px;
  width: 70%;
}

.tvsp-retour {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #ebeef0;
  border-radius: 3px;
  padding: 0px 42px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  cursor: pointer;
  box-shadow: 0px 10px 40px 0px rgba(18, 32, 118, 0.08);
  background-color: var(--global-color-1);
}

.tvsp-retour:hover {
  background-color: var(--global-color-2);
}

.tvsp-retour-txt {
  white-space: nowrap;
}
