.favoris-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.favoris-subcontainer {
  max-width: 1500px;
  width: 90%;
}

.switchviewcontainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.favorisbloc {
  margin-right: 10px;
}

.favorisbloc:hover {
  cursor: pointer;
}

.favorislist:hover {
  cursor: pointer;
}

.favoris-noresult-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.favoris-noresult-subcontainer {
  background-color: rgb(230, 233, 237);
  padding: 4rem 2rem;
  color: black;
  max-width: 1500px;
  width: 85%;
}

.favoris-noresult-h1 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.favoris-noresult-p {
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 1rem;
}

.favoris-noresult-btn {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.favoris-noresult-btn:hover {
  background-color: rgb(165, 181, 198);
  text-decoration: none;
  color: #fff;
}
