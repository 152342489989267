.login-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.login-subcontainer {
  padding: 66px 56px;
  text-align: center;
  position: relative;
  margin: 0 auto 0 auto;
  height: auto;
  width: 372px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 30px -4px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-logo {
  margin-bottom: 32px;
  width: 80%;
  height: auto;
  max-width: 182px;
}

.login-input {
  margin: 0 0 18px 0;
  padding: 16px 20px 17px 20px;
  border: 1px solid #e5e5e5;
  border-bottom: 2px solid #e5e5e5;
  border-radius: 4px;
  width: 100%;
  display: block;
  font-weight: 600;
  line-height: 14px;
  font-size: 14px;
  color: #000;
}

.login-checkbox-label {
  color: #222;
  user-select: none;
  padding-left: 8px;
  display: inline-block;
  margin-bottom: 20px;
  font-size: 13px;
  line-height: 13px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.login-button {
  padding: 16px 20px 17px 20px;
  display: block;
  color: #fff;
  background: #000;
  font-weight: 600;
  line-height: 1rem;
  font-size: 1rem;
  width: 100%;
  text-align: center;
  border: 0;
  border-radius: 4px;
  font: 600 13.3333px Arial;
}
