.pit-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  /* background-color: white; */
}
.pit-container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.pit-container {
  overflow-y: scroll;
  width: 100%;
  /* height: 350px; */
  /* margin-bottom: 30px; */
  display: flex;
  align-items: center;
}
