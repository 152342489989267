.plv-star:hover {
  cursor: pointer;
}

.plv-root-container {
  display: flex;
  flex-direction: column;
  opacity: 1;
  align-items: center;
}

.plv-linktype-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  /* margin-top: 20px; */
  /* opacity: 1; */
}

.plv-linktype-heading {
  font-size: 25px;
  color: white;
  margin-top: 20px;
  margin-bottom: 30px;
}

.plv-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.plv-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  /* margin-bottom: 20px; */
  width: 100%;
}

.plv-search-container {
  display: flex;
  height: 75px;
  width: 100%;
}

.plv-search-subcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.plv-search-subcontainer:hover {
  text-decoration: none;
}

.plv-el-root-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.plv-subcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.plv-subcontainer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.plv-subcontainer-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.plv-subcontainer-content {
  margin-left: 30px;
  width: 50%;
}

.plv-subcontainer-content-id {
  padding: 0 22px 0 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
  color: #b6b6b6;
}

.plv-subcontainer-content-type {
  font-weight: 700;
  font-size: 11px;
  line-height: 11px;
  display: inline-block;
  padding: 5px 7px;
  color: #646464;
  background: #fff;
  border-radius: 4px;
  margin-right: 20px;
}

.plv-subcontainer-container {
  width: 100%;
}

.plv-subcontainer-content-title {
  font-size: 13px;
  line-height: 13px;
  color: #232323;
  font-weight: 600;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.plv-subcontainer-content-subtitle-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.plv-subcontainer-content-subtitle {
  color: #b6b6b6;
  font-weight: 500;
  font-size: 12px;
  margin: 0;
  white-space: nowrap;
}

.plv-star-container {
  align-self: flex-start;
  margin-right: 10px;
}

.plv-star-active {
  cursor: pointer;
  color: rgb(217, 165, 31);
  font-size: 16px;
}

.plv-star-active:hover {
  color: rgb(196, 33, 70);
}

.plv-star {
  color: rgb(165, 181, 198);
  font-size: 16px;
}

.plv-star:hover {
  color: rgb(64, 145, 213);
}

.plv-button {
  height: 100px;
  width: 100px;
  border: none;
  outline: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* background-color: var(--global-color-3); */
}

.plv-search-button {
  height: 75px;
  width: 75px;
  min-width: 75px;
  border: none;
  outline: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.listCheckIcon {
  font-size: 50px;
  color: white;
}

@keyframes custom-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.75;
  }
}

.plv-selected {
  width: 100%;
  height: 100%;
  opacity: 0.75 !important;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: custom-fadein;
  animation-duration: 1s;
}

/* .plv-selected {
  width: 100%;
  height: 100%;
  opacity: 0.5;
} */

@media only screen and (max-width: 1024px) {
  .plv-container {
    height: 100px;
  }

  .plv-root-container .plv-button {
    height: 100px;
    width: 150px;
    min-width: 150px;
  }
}

.plv-cart-remove {
  color: black;
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.plv-linktype-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.plv-caretdown {
  color: white;
  font-size: 60px;
  margin-left: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 850px) {
  .plv-subcontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
  }

  .plv-subcontainer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
  }
}

.plv-icons-container {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.plv-right-search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plv-select-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
}

.plv-root-container-rm {
  display: flex;
  flex-direction: column;
  opacity: 1;
  justify-content: flex-start;
  align-items: center;
  min-height: 500px;
}

.plv-raccourcis-container {
  display: none;
}

.plv-container:hover .plv-raccourcis-container {
  align-self: flex-start;
  display: flex;
}

.plv-search-container:hover .plv-raccourcis-container {
  align-self: flex-start;
  display: flex;
}

.plv-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: black;
  font-size: 12px;
  line-height: 12px;
  margin-top: 5px;
}
