.mpic-arrow {
  width: 30px;
  height: 80px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mpic-carouselimg-active {
  max-width: 80px;
  max-height: 80px;
}

.mpic-carouselimg {
  max-width: 80px;
  max-height: 80px;
  opacity: 0.5;
}

.mpic-container {
  width: 80%;
  height: 80px;
}

.mpic-arrow-secondary {
  width: 40px;
  height: 46px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mpic-carouselimg-active-secondary {
  max-width: 72px;
  max-height: 46px;
}

.mpic-carouselimg-secondary {
  max-width: 72px;
  max-height: 46px;
  opacity: 0.5;
}

.mpic-container-secondary {
  max-width: 564px;
  width: 90%;
  height: 46px;
}
