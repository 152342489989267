@media only screen and (min-width: 1280px) {
  .search-input-container {
    display: none;
  }

  .search-input {
    display: none;
  }
}

@media only screen and (max-width: 1280px) {
  .search-input-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .search-input-subcontainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 1300px;
    max-width: 90%;
  }

  .search-input {
    display: block;
    width: 300px;
    margin-right: 20px;
  }
}

.search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-subcontainer {
  width: 1500px;
  max-width: 90%;
}

.search-heading {
  color: #b6b6b6;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25rem;
  vertical-align: middle;
}

.search-heading-b {
  color: rgb(32, 32, 32);
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25rem;
  vertical-align: middle;
}

.search-heading-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.search-type-heading {
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 700;
  color: #b6b6b6;
  font-size: 11px;
  line-height: 11px;
  margin-bottom: 15px;
}

.search-divider {
  margin-left: 5px;
}

.search-pagination-innerClass {
  margin-top: 75px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  height: 30px;
}

.search-pagination-itemClass {
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(217, 222, 226);
  cursor: pointer;
}

.search-pagination-activeClass {
  background-color: rgb(75, 75, 75);
  border: 1px solid rgb(75, 75, 75);
}

.search-pagination-itemClass:hover {
  background-color: rgb(230, 233, 237);
}

.search-pagination-activeClass:hover {
  background-color: rgb(75, 75, 75);
}

.search-pagination-itemClassFirst {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.search-pagination-itemClassPrev {
  width: auto;
}

.search-pagination-itemClassNext {
  width: auto;
}

.search-pagination-itemClassLast {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.search-pagination-linkClass {
  color: black;
}

.search-pagination-activeLinkClass {
  color: white;
}

.search-pagination-itemClass:hover > .search-pagination-linkClass {
  text-decoration: none;
  color: rgb(0, 76, 170);
}

.search-pagination-activeClass:hover > .search-pagination-activeLinkClass {
  text-decoration: none;
  color: white;
}

.search-pagination-linkClassFirst {
}

.search-pagination-linkClassPrev {
  margin-left: 10px;
  margin-right: 10px;
}

.search-pagination-linkClassNext {
  margin-left: 10px;
  margin-right: 10px;
}

.search-pagination-linkClassLast {
}

.search-pagination-disabledClass:hover {
  background-color: white;
  cursor: default;
}

.search-pagination-disabledClass > .search-pagination-linkClassFirst {
  color: rgb(95, 104, 112);
  cursor: default;
}

.search-pagination-disabledClass > .search-pagination-linkClassFirst:hover {
  color: rgb(95, 104, 112);
  cursor: default;
}

.search-pagination-disabledClass:hover > .search-pagination-linkClassFirst {
  color: rgb(95, 104, 112);
}

.search-pagination-disabledClass > .search-pagination-linkClassPrev {
  color: rgb(95, 104, 112);
  cursor: default;
}

.search-pagination-disabledClass > .search-pagination-linkClassPrev:hover {
  color: rgb(95, 104, 112);
  cursor: default;
}

.search-pagination-disabledClass:hover > .search-pagination-linkClassPrev {
  color: rgb(95, 104, 112);
}

.search-pagination-disabledClass:hover > .search-pagination-linkClassLast {
  color: rgb(95, 104, 112);
}

.search-pagination-disabledClass > .search-pagination-linkClassLast {
  color: rgb(95, 104, 112);
  cursor: default;
}

.search-pagination-disabledClass > .search-pagination-linkClassLast:hover {
  color: rgb(95, 104, 112);
  cursor: default;
}

.search-pagination-disabledClass:hover > .search-pagination-linkClassLast {
  color: rgb(95, 104, 112);
}

.search-pagination-disabledClass > .search-pagination-linkClassNext {
  color: rgb(95, 104, 112);
  cursor: default;
}

.search-pagination-disabledClass > .search-pagination-linkClassNext:hover {
  color: rgb(95, 104, 112);
  cursor: default;
}

.search-pagination-disabledClass:hover > .search-pagination-linkClassNext {
  color: rgb(95, 104, 112);
}

.search-btn {
  margin-top: 22px;
  color: white;
  max-width: 100%;
  background-color: var(--global-color-1);
  border: 1px solid transparent;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  padding: 0 42px 0 42px;
  height: 44px;
  border-radius: 0.25rem;
  margin-bottom: 44px;
}

.search-btn:hover {
  background-color: var(--global-color-2);
}

.search-btn-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.search-retour {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #ebeef0;
  border-radius: 3px;
  background-color: var(--global-color-1);
  padding: 0px 42px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  cursor: pointer;
  box-shadow: 0px 10px 40px 0px rgba(18, 32, 118, 0.08);
  margin-right: 38px;
}

.search-retour:hover {
  background-color: var(--global-color-2);
}

.search-retour-txt {
  white-space: nowrap;
}

.search-loader {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
}
