.denied_header{
    position:absolute;
    top:30px;
    left:30px;
}

.denied_msg{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    align-items: center
}


.denied-footerstyle-b:focus {
    outline: none;
}

.denied-footerstyle-b:hover {
    background-color: #3d3d3d;
}

.denied-footerstyle-b {
    cursor: pointer;
    margin-right: 20px;
    background: #3d3d3d;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    line-height: 1rem;
    font-size: 1rem;
    padding: 0 30px 0 30px;
    height: 44px;
    line-height: 43px;
}

.denied_btn-container{
    margin-top: 100px;
}