.mediapage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mediapage-path {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 28px 0px;
  width: 85%;
  max-width: 1500px;
}

.mediapage-carouselcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: black;
  border-top: 1px solid #e9eff5;
  border-bottom: 1px solid #e9eff5;
  width: 100%;

  padding: 36px 0;
}

.mediapage-mainimage {
  width: 80%;
  height: 526px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.mediapage-video {
  width: 700px;
  height: 526px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.video-react .video-react-big-play-button {
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.mediapage-name {
  margin: 0 0 10px 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: #fff;
}

.mediapage-date {
  padding: 0 10px;
  margin: 0;
  display: inline-block;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  color: #b6b6b6;
}

.mediapage-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

.mediapage-download-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(53, 53, 53);
  border-width: 0px;
  color: white;
  margin-right: 15px;
  outline: none;
  border-radius: 2px;
  padding: 5px 15px 5px 15px;
}

.mediapage-download-btn:focus {
  outline: none;
}

.mediapage-download-a {
  padding-left: 20px;
  padding-right: 20px;
}

.mediapage-add-btn {
  background-color: rgb(64, 145, 213);
  border-width: 0px;
  color: white;
  outline: none;
  border-radius: 2px;
  padding: 5px 15px 5px 15px;
}

.mediapage-add-btn:focus {
  outline: none;
}

.mediapage-fullinfo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 15px;
  min-height: 75px;
}

.mediapage-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 15px;
}

.mediapage-cart-icon {
  font-size: 20px;
  margin-right: 5px;
}
