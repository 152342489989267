@import url(https://fonts.googleapis.com/css?family=Noto+Sans);
.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height:100vh;
}
/* Dropdown Button */

.ld-chevrondown {
  margin-left: 20px;
}

.ld-dropbtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  padding: 0.25rem 2rem 0.25rem 2rem;
}

.ld-dropbtn:focus {
  outline: none;
}

.ld-dropbtn:hover {
  cursor: pointer;
}

/* Styles pour le dropdown de sélection de langue */

.ld-dropdown-content {
  display: none;
  padding: 12px 20px;
  width: 180px !important;
}

.ld-dropdown:hover .ld-dropdown-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  border-radius: 8px;
  width: 150px;
}

.ld-dropdown-content p {
  color: #444;
  border-bottom: 1px solid #ccc;
  font-size: 12px;
  /* margin-left: 6px;
  margin-right: 6px; */
  line-height: 20px;
  margin-bottom: 5px;
}

.ld-dropdown-content a:hover {
  color: black;
}

.ld-dropdown-element {
  display: flex;
  flex-direction: row;
}

.ld-dropdown-element-name {
  text-decoration: none;
  color: lightgrey;
  font-size: 14px;
  margin-left: 4px;
  width: 120px;
  margin-bottom: -8px;
  cursor: pointer;
}

.ld-check {
  margin-top: 10px;
  color: black;
  opacity: 0.3;
}

.langs .ld-dropbtn{
  padding: 0px;
}

.langs.ld-dropdown{
  min-width: 140px
}

.header-container {
  height: 66px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 999;
  /* background: linear-gradient(
    to right,
    rgba(21, 31, 42, 0.95) 0%,
    rgba(32, 57, 85, 0.95) 100%
  ); */
  background-color: var(--entete);
}

.header-subcontainer {
  width: 90%;
  max-width: 1500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-leftleft {
  display: none;
}

.header-left {
}

.header-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header-bars {
  display: none;
}

.header-logo {
  vertical-align: middle;
  max-width: 172px;
}

.header-center {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header-searchinput {
  max-width: 350px;
  width: 90%;
}

.header-searchinput input {
  height: 35px;
  width: 100%;
  border-width: 0px;
  border-radius: 20px;
  padding: 0.25rem 3rem 0.25rem 3rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAzVJREFUeNp0U1tIk2EYfnf2jAc8o3MHRTfdShHBqZRYat4Y7MLFkkIvgqWQCN7kjTeBF10IWVceIhCKSIWCjEARMRNdG4o6nPOwubnpnA7xsOl6vkFe2Qcf+/53//e8z+H9OQcHB+R2u2loaIj29vaKPR6P1mw238U5KTs721VaWvrt8PDwY21t7UZZWRnl5eXR5eUlXa+TkxNaWloS6HS6F/Hx8X6UQmzHxcWF/p0zMzMdbW1tj4xGI6EBa3S9+VarlTM+Pv58ZGTkNQMsLCyckUqlY+jijo2NFc/Ozmq3traK+vv7B5RKJTc/P/+D3+8nDocTJsBTqVTqnp6e4fPzc5Fer39XU1NjyMrKmoA0U0NDw1RlZeVEMBiUWCwWxfLyshrPo1FRUUenp6d0cXFB3Lm5uaajo6O48vLymZaWlpehUMjFOjCdzJ+MjAxrb2/vs/T0dLvNZpONjY09FAgEFAgEwpsHg14dHx9nNjY29pWUlPyED+T1eml/f59gYhgoNTXVv7q6qgCLYnT1AeAzjCbUiA8jEvh8fig6OtrF4/HCugBIMI4ATk6nk3Z2digtLW2D/WcymeJ3d3fp6uoq/C5fLBbvra2tyVHMAU2CqTQ5OUkSiYTgC3V1dZFMJiOkpGQXKioqPO3t7cRkhgFg2lcAaObn53WgPRgZGbkrFAqJy+USWBHqDFSxuLj4AEwDeH8KINcAXCB/QmfnysqKsrOz863L5ZIyk1hMIpGI5X6ru7t7GOAJiPFPfX39iN1uJ5/PF5bIYQfMgN5gMAxCFz8xMdEhl8u/FxQU2KBXAefvI6Uk1g2mrmu12iZ4tMASYIbzOjo6WCcz6NkQmwoJ5Gxvb9/G5WqwKUKswtzcXCNYBR0Oh2Rzc7Ma0c7DHzuTyQNtwqRRcnKyWa1Wf8F3sZWSkuLFiNs1Gs0Mon3T3NzchdoPXL4HUBmo3wGb3zExMQ4uo8b0Ytro7OzMDhl9dXV1esTW0Nra+hQfzwAuHKO+CAN16L4OJvLp6en3uFfGpRsWG1M2QMxpppU1YLnj93dVVdVjzIgFTPJGR0eHbgT432IsAfIL38MTMFmIiIjw/BVgAKbVxB1zTtOxAAAAAElFTkSuQmCC) no-repeat 12px center;
  background-color: white;
  outline: medium none;
  font-size: 14px;
  vertical-align: middle;
}

.header-parametres {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  /* opacity: 0.3; */
}

.header-parametres:hover {
  color: white;
  opacity: 1;
  text-decoration: none;
}

.header-parametres-icon {
  margin-right: 10px;
  font-size: 20px;
}

.header-parametres-p {
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}

.header-cart {
  margin-left: 30px;
  color: white;
  /* opacity: 0.3; */
}

.header-cart:hover {
  color: white;
  opacity: 1;
}

.header-cart-icon {
  font-size: 20px;
  /* vertical-align: middle; */
}

.header-favoris {
  margin-left: 30px;
  color: white;
  /* opacity: 0.3; */
}

.header-favoris:hover {
  color: white;
  opacity: 1;
}

.header-favoris-star {
  font-size: 20px;
  /* vertical-align: middle; */
}

.divider {
  margin-left: 50px;
  height: 66px;
  border-left: 1px solid white;
  opacity: 0.2;
}

.header-logout-icon {
  margin-right: 10px;
  font-size: 20px;
}

.header-logout-p {
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}

.header-logout {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  padding: 0.5rem 2rem 0.5rem 2rem;
  /* opacity: 0.3; */
  outline: none;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
}

.header-logout:hover {
  color: white;
  opacity: 1;
  cursor: pointer;
  text-decoration: none;
}

.header-logout:focus {
  outline: none;
}

@media only screen and (min-width: 840px) {
  #dropdownheadermenu {
    display: none;
  }

  .header-forsmallscreensize {
    display: none;
  }
}

.header-search {
  display: none;
}

@media only screen and (max-width: 1100px) and (min-width: 850px) {
  .searchIcon {
    color: white;
    /* opacity: 0.3; */
    margin-right: 40px;
  }

  .searchIcon:hover {
    opacity: 1;
  }

  .header-searchinput {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .header-logout {
    margin-right: 0px;
  }

  .header-searchinput {
    display: none;
  }

  .header-search {
    display: block;
    color: white;
    /* opacity: 0.3; */
  }

  .header-search:hover {
    color: white;
    opacity: 1;
  }
}

@media only screen and (max-width: 850px) {
  .header-leftleft {
    display: block;
  }

  .header-bars {
    display: block;
    color: white;
    /* opacity: 0.3; */
    cursor: pointer;
  }

  .header-center {
    display: none;
  }

  .header-bars:hover {
    opacity: 1;
  }

  .header-logo {
    margin: 0px;
  }

  .langs {
    display: none;
  }

  .header-parametres {
    display: none;
  }

  .header-favoris {
    display: none;
  }

  .header-cart {
    display: none;
  }

  .divider {
    display: none;
  }

  .header-logout {
    display: none;
  }

  .header-forsmallscreensize {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 100%;
  }

  #dropdownheadermenu {
    position: fixed;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
    z-index: 999;
    background: transparent;
  }

  .dropdownlangs {
    color: black;
    margin-top: 10px;
  }

  .dropdownlangs:hover {
    margin-bottom: 450px;
  }

  .dropdownoverlay {
    position: fixed;
    top: 322px;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.7;
  }

  .dropdownlangs:hover ~ .dropdownoverlay {
    top: 773px;
  }

  .dropdown-linkitem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 16px;
    border: none;
    padding: 0.5rem 2rem 0.5rem 2rem;
    /* opacity: 0.3; */
    outline: none;
    cursor: pointer;
    width: 100%;
  }

  .dropdown-linkitem:hover {
    color: black;
    opacity: 1;
    text-decoration: none;
    background-color: rgb(242, 246, 248);
  }

  .dropdown-icon {
    margin-right: 5px;
    line-height: 10px;
  }
}

.profileImg {
  height: 40px;
}
.userinfodropdowncontent {
  width: 250px !important;
}

.popupContainer {
}
.popUpRow {
  margin: 0;
}

.popInput {
  border: none;
}
.popInputFull {
  width: 100%;
}

.popupItemPadding {
}

.popupButton {
  width: 100%;
  height: 36px;
  background: none;
  border: 1px solid blue;
  border-radius: 25px;
  color: blue;
  text-align: center;
  font-size: 15px;
  line-height: 15px;
  font-weight: bold;
  outline: none;
}
.savebutton {
  background: #cacaca;
  color: black;
  border: #cacaca;
  font-weight: bold;
}

.popupButtonInactive {
  width: 100%;
  height: 36px;
  background: none;
  border: none;
  border-radius: 25px;
  color: #cacaca;
  text-align: center;
  font-size: 15px;
  line-height: 15px;

  outline: none;
}
.ztoItemLabel {
  font-weight: bold;
}
.popUpHeader {
  font-weight: bold;
}
.closeArrow {
  text-align: right;
  font-weight: bold;
  font-size: 20px;
}

.headerMargin {
  margin-left: 15px;
}

button:focus {
  outline: none;
  box-shadow: none;
}
input:focus {
  outline: none;
  box-shadow: none;
}

select:focus {
  outline: none;
  box-shadow: none;
  border: none;
}
select {
  width: 100%;
  border: none;
}
input {
  width: 100%;
}

textarea {
  width: 100%;
}

/** Modal **/
.ztogroupedrow {
  margin-bottom: 15px;
}
.ztoPopUpCont {
  /* height: 740px;
  position: relative; */
  font-size: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.ztoPopUpCont:hover {
  overflow: auto;
}

.ztoAudiencePopUpCont {
  font-size: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.ztoAudiencePopUpCont:hover {
  overflow: auto;
}

.ztoactionButton {
  /* position: absolute; */
  bottom: 25px;
  text-align: right;
}

.ztomandory {
  color: red;
}

.ztoerrormsg {
  color: red;
  text-align: center;
}

.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.ztoModal > .closepopup {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.ztorow {
  margin-bottom: 20px;
}

.ztoModalTitle {
  margin-bottom: 35px;
}
.saveBtnContainer {
  margin-top: 20px;
}

.button_1 {
  width: 225px;
  height: 40px;
  background-color: black;
  color: white;
  font-size: 16px;
  outline: none;
  border-radius: 4px;
}

.button_1:hover {
  color: var(--global-color-2);
  cursor: pointer;
}

.headingText1 {
  letter-spacing: 4px;
  color: #b5b5b5;
  font-size: 12px;
  font-weight: bold;
}

.enabledLink {
  color: #b6b6b6;
}

.disabledLink {
  color: var(--global-color-1);
}

.link {
  margin-top: 6px;
  margin-bottom: 6px;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.35rem;
  margin-right: 25px;
}

.link:hover {
  color: var(--global-color-2);
  text-decoration: none;
}

@media only screen and (max-width: 850px) {
  .link {
    margin-top: 6px;
    margin-bottom: 6px;
    text-decoration: none;
    font-weight: 600;
    font-size: 15px;
    line-height: 1.35rem;
    margin-right: 25px;
  }
}

.produits {
  display: flex;
  flex-flow: row wrap;
  height: 220px;
  overflow: hidden;
}

.lb-item-info-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(72, 68, 68);
  opacity: 0.9;
  font-size: 12px;
  color: white;
  width: 100%;
  height: 66px;
}

.lb-item-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  opacity: 0.9;
  font-size: 12px;
  color: white;
  width: 82%;
}

.lb-item-dates {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.lb-item-date1 {
  margin-right: 3px;
}

.lb-item-date1-p {
  margin-right: 15px;
}

.lb-item-date2 {
  margin-right: 3px;
}

.lb-produit-container {
}

.lb-produit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  background-color: var(--global-color-3);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 220px;
  height: 220px;
  border: none;
  outline: none;
  background-size: 100%;
  cursor: pointer;
  /* margin-left: 10px; */
  margin-right: 20px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 4px solid black;
}

.lb-produit:hover {
  border: none;
}

.lb-produit:hover .lb-item-info-container {
  height: 70px;
}

.lb-link:hover {
  text-decoration: none;
}

.lpb-item-info-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(72, 68, 68);
  opacity: 0.9;
  font-size: 12px;
  color: white;
  width: 100%;
  height: 66px;
}

.lpb-item-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  opacity: 0.9;
  font-size: 12px;
  color: white;
  width: 82%;
}

.lpb-item-title {
  text-align: start;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.lpb-item-dates {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.lpb-item-date1 {
  margin-right: 3px;
}

.lpb-item-date1-p {
  margin-right: 15px;
}

.lpb-item-date2 {
  margin-right: 3px;
}

.lpb-produit-container {
}

.lpb-produit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  background-color: var(--global-color-3);
}

.lpb-produits {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.lpb-produit {
  width: 220px;
  height: 220px;
  border: none;
  outline: none;
  background-size: 100%;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 4px solid black;
}

.lpb-produit:hover {
  border: none;
}

.lpb-produit:hover .lpb-item-info-container {
  height: 70px;
}

.lpb-link:hover {
  text-decoration: none;
}

.mic-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
}

.mic-arrow {
  color: rgb(100, 100, 100);
  font-size: 50px;
  cursor: pointer;
}

.mic-item {
  margin-right: 10px;
  margin-left: 10px;
  width: 100%;
  height: auto;
  max-width: 300px;
}

.mic-item-background {
  background-size: cover;
}

.mic-item-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.mic-item-button {
  margin-top: 35px;
  margin-right: 10px;
  color: white;
}

.mic-item-button:hover {
  cursor: pointer;
}

.mic-item-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(72, 68, 68);
  opacity: 0.9;
  font-size: 12px;
  margin-top: 60px;
  color: white;
}

.mic-item-dates {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mic-item-date1 {
  margin-right: 3px;
}

.mic-item-date1-p {
  margin-right: 5px;
}

.mic-item-date2 {
  margin-right: 3px;
}

.home-subheader {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e9e9e9;
  position: relative;
}

.sh-img-bandeau {
  position: relative;
  background-repeat: no-repeat !important;
  background-position: right 70% !important;
  background-size: cover !important;
  height: 200px;
  padding: 42px 0 0 0;
  line-height: 200px;
  margin: 0 0 0 0;
  border-bottom: 1px solid #e9e9e9;
}

.sh-subcontainer {
  /* min-height: 66px; */
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  background-repeat: no-repeat !important;
  background-position: right 70% !important;
  background-size: cover !important;
  /* height: inherit; */
  /* background: linear-gradient(to left, rgba(0,0,0,0), rgba(255, 0, 255, 0.95), rgba(255, 255, 255, 1)); */
  position: relative;
}

.sh-subsubcontainer {
  width: 90%;
  max-width: 1500px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.retour {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-color: lightgrey;
  border-radius: 4px;
  border-style: solid;
  background-color: var(--global-color-1);
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
  color: white;
  box-shadow: 0px 10px 40px 0px rgba(18, 32, 118, 0.08);
  height: 44px;
}

.retour:hover {
  background-color: var(--global-color-2);
}

.icon {
  margin-right: 10px;
}

.subheader-title {
  font-size: 16px;
  color: black;
  font-weight: bold;
}

@media only screen and (max-width: 850px) {
  .MuiTreeView-root {
    display: none;
  }
}

.iai-container {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* height: 550px; */
}

.iai-productpage-subcontainer {
  max-width: 1024px;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
}

.iai-productpage-image {
  width: 49%;
  min-width: 400px;
  height: 550px;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  /* background-image: url('../../../images/no-image.png'); */
}

.iai-fullinfocontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(230, 233, 237);
  width: 49%;
  min-width: 400px;
  /* height: 620px; */
}

.iai-infocontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5%;
}

.iai-infofavori-container {
  align-self: flex-end;
  margin-top: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
}

.iai-infofavori-active {
  cursor: pointer;
  color: rgb(217, 165, 31);
  font-size: 27px;
}

.iai-infofavori-active:hover {
  color: rgb(196, 33, 70);
}

.iai-infofavori {
  cursor: pointer;
  color: rgb(165, 181, 198);
  font-size: 27px;
}

.iai-infofavori:hover {
  color: rgb(64, 145, 213);
}

.iai-infotitle {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 500;
}

.iai-infocart-active {
  cursor: pointer;
  color: rgb(217, 165, 31);
  font-size: 27px;
}

.iai-infocart-active:hover {
  color: rgb(196, 33, 70);
}

.iai-infocart {
  cursor: pointer;
  color: rgb(165, 181, 198);
  font-size: 27px;
}

.iai-infocart:hover {
  color: rgb(64, 145, 213);
}

@media only screen and (max-width: 1200px) {
  .iai-productpage-subcontainer {
    max-width: 1024px;
    /* width: 90%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
  }

  .iai-productpage-image {
    width: 49%;
    min-width: 400px;
    height: 550px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    /* background-image: url('../../../images/no-image.png'); */
  }
}

.iai-productpage-loadingimage {
  width: 49%;
  min-width: 400px;
  height: 550px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pit-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  /* background-color: white; */
}
.pit-container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.pit-container {
  overflow-y: scroll;
  width: 100%;
  /* height: 350px; */
  /* margin-bottom: 30px; */
  display: flex;
  align-items: center;
}

.mpic-arrow {
  width: 30px;
  height: 80px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mpic-carouselimg-active {
  max-width: 80px;
  max-height: 80px;
}

.mpic-carouselimg {
  max-width: 80px;
  max-height: 80px;
  opacity: 0.5;
}

.mpic-container {
  width: 80%;
  height: 80px;
}

.mpic-arrow-secondary {
  width: 40px;
  height: 46px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mpic-carouselimg-active-secondary {
  max-width: 72px;
  max-height: 46px;
}

.mpic-carouselimg-secondary {
  max-width: 72px;
  max-height: 46px;
  opacity: 0.5;
}

.mpic-container-secondary {
  max-width: 564px;
  width: 90%;
  height: 46px;
}

.navstyle2 {
  color: black;
}

.navstyle2active {
  background-color: rgb(242, 246, 248);
  border-style: solid;
  border-radius: 4px;
  border-color: grey;
  border-width: 1px;
}

.nav-tabs {
  /* height: 33px; */
  line-height: initial;
}

.nav-item {
  color: black;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: black;
  background-color: white;
}

.nav-link {
  color: black;
}

.nav-link:hover {
  color: black;
}

.active {
}

.api-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.api-subcontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.api-subsubcontainer {
  width: 100%;
  max-width: 1024px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 14px;
}

.api-navlink {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.25rem;
  border: 1px solid white;
  white-space: nowrap;
}

.api-tabs {
  font-size: 14px;
  width: 100%;
  /* width: 90%; */
}

.api-fields-container {
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; */
}

.tab-content > .active {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.tab-content {
  width: 100%;
}

.row {
  width: 100%;
}

.tab-pane {
  width: 100%;
}

.api-field1 {
  margin-right: 25px;
  margin-bottom: 25px;
  min-height: 100px;
  min-width: 250px;
  width: 47%;
  background-color: rgb(242, 246, 248);
  border-style: solid;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

.api-field2 {
  margin-bottom: 25px;
  min-height: 100px;
  min-width: 250px;
  width: 47%;
  background-color: rgb(242, 246, 248);
  border-style: solid;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

.api-col {
  max-width: 300px;
  /* margin-right: 30px; */
}

.api-field-h4 {
  letter-spacing: -0.5px;
  margin: 0 0 14px 0;
  color: #6b7c8d;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
}

.api-field-p {
  letter-spacing: -0.5px;
  margin: 0;
  color: #232323;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.25rem;
  min-height: 20px;
}

@media only screen and (max-width: 1024px) {
  .row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
  .tab-content {
    width: 100%;
  }
  .api-field1 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-top: 25px;
  }

  .api-field2 {
    width: 100%;
    margin-bottom: 0px;
    margin-top: 25px;
  }
}

.hsi-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(242, 246, 248);
  min-height: 400px;
  max-height: 600px;
  border: 1px rgb(230, 236, 243) solid;
  border-left: none;
  border-right: none;
  font-size: 13px;
  color: #232323;
  font-weight: 600;
}

.hsi-title-container {
  width: 1024px;
  max-width: 90%;
  display: flex;
  align-items: flex-end;
  min-height: 80px;
}

.hsi-skus {
  line-height: 80px;
  letter-spacing: 3px;
  color: #232323;
  font-size: 12px;
}

.hsi-subroot {
  overflow: hidden;
  height: 60%;
  width: 1024px;
  max-width: 90%;
  margin-bottom: 75px;
  z-index: 1;
}

.hsi-subroot:hover {
  overflow: auto;
}

.hsi-subroot::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

.hsi-subroot::-webkit-scrollbar-corner {
  background-color: rgb(242, 246, 248);
}

.hsi-subroot::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.hsi-row1 {
  background-color: white;
  height: 65px;
}

.hsi-row2 {
  background-color: rgb(242, 246, 248);
  height: 65px;
}

.hsi-header {
  background-color: rgb(242, 246, 248);
  height: 75px;
}

.hsi-hitem {
  background-color: rgb(242, 246, 248);
  padding: 0px 20px;
  white-space: nowrap;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  color: #6b7c8d;
  vertical-align: middle;
  font-size: 13px;
  letter-spacing: -0.5px;
  font-weight: 600;
}

.hsi-item {
  padding: 5px 20px;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 13px;
  letter-spacing: -0.5px;
  font-weight: 600;
}

.hsi-loader {
  justify-self: center;
  align-self: center;
}

/* .dl-arrow {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.dl-arrow-icon {
  font-size: 40px;
  color: black;
  cursor: pointer;
}

.dl-carousel {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  z-index: 999;
}

.BrainhubCarousel__trackContainer {
  /* z-index: -1; */
}

.dl-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.dl-subcontainer {
  width: 1024px;
  max-width: 90%;
}

.dl-element-link:hover {
  text-decoration: none;
}

.dl-element-container {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.7)
  ); */
  background-size: cover;
  color: white;
  padding: 20px;
}

.dl-element-img {
  width: 372;
  height: 220px;
  /* border: solid 1px black; */
  box-shadow: 0 0 14px 0 rgba(206, 206, 206, 1);
  margin-bottom: 10px;
}

.dl-element-p {
  font-size: 12px;
  line-height: 14px;
  width: 80%;
  color: black;
}

.BrainhubCarousel__arrow--disable {
  visibility: hidden;
}

.dlt-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(242, 246, 248); */
  min-height: 400px;
  max-height: 600px;
  /* border: 1px rgb(230, 236, 243) solid; */
  border-left: none;
  border-right: none;
  font-size: 13px;
  color: #232323;
  font-weight: 600;
}

.dlt-title-container {
  width: 512px;
  max-width: 90%;
  display: flex;
  align-items: flex-end;
  min-height: 80px;
}

.dlt-skus {
  line-height: 80px;
  letter-spacing: 3px;
  color: #232323;
  font-size: 12px;
}

.dlt-subroot {
  overflow: hidden;
  height: 60%;
  width: 512px;
  max-width: 90%;
  margin-bottom: 75px;
  z-index: 1;
}

.dlt-subroot:hover {
  overflow: auto;
}

.dlt-subroot::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}
.dlt-subroot::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.dlt-row-link {
  color: black;
}

.dlt-row-link:hover {
  text-decoration: none;
}

.dlt-row1 {
  text-align: center;
  background-color: rgb(242, 246, 248);
  height: auto;
}

.dlt-row2 {
  text-align: center;
  background-color: white;
  height: auto;
}

.dlt-header {
  text-align: center;
  background-color: white;
  height: auto;
}

.dlt-hitem {
  padding: 0px 20px;
  white-space: nowrap;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background-color: white;
  color: #6b7c8d;
  vertical-align: middle;
  font-size: 13px;
  letter-spacing: -0.5px;
  font-weight: 600;
}

.dlt-item {
  padding: 5px 20px;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 13px;
  letter-spacing: -0.5px;
  font-weight: 600;
}

.dlt-loader {
  justify-self: center;
  align-self: center;
}

.dli-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.dli-subcontainer {
  max-width: 1024px;
  width: 90%;
}

.rm-container {
  /* margin-top: 50px; */
  background-color: rgb(32, 32, 32);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.rm-subcontainer {
  display: flex;
  flex-direction: column;
  width: 1024px;
  max-width: 90%;
}

.rm-firstrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 86px;
  margin-bottom: 20px;
}

.rm-firstrow-title {
  color: rgb(109, 109, 109);
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  letter-spacing: 3px;
}

.rm-firstrow-link {
  background-color: rgb(22, 22, 22);
  border-width: 0px;
  /* width: 250px; */
  border-radius: 3px;
  color: white;
  outline: none;
  text-decoration: none;
  text-align: center;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
  padding: 0 42px 0 42px;
  height: 44px;
  line-height: 43px;
}

.rm-firstrow-link:hover {
  outline: none;
  background-color: rgb(165, 181, 198);
}

@media only screen and (max-width: 1024px) {
  .rm-subcontainer {
    margin-left: 50px;
    margin-right: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .rm-subcontainer {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.rm-footer-hide {
  display: none;
}

.rm-footerstyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(39, 39, 39);
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  position: fixed;
  left: 0;
  bottom: 10px;
  height: 60px;
  width: 1024px;
  max-width: 90%;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  font-size: 14px;
}

.rm-footerstyle-p {
  margin-left: 50px;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.375rem;
}

.rm-footerstyle-b:focus {
  outline: none;
}

.rm-footerstyle-b:hover {
  background-color: var(--global-color-2);
}

.rm-footerstyle-b {
  cursor: pointer;
  margin-right: 20px;
  background: #3d3d3d;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  line-height: 1rem;
  font-size: 1rem;
  padding: 0 30px 0 30px;
  height: 44px;
  line-height: 43px;
}

.vm-popup-content {
  /* width: 1000px; */
}

.vm-container {
  background-color: rgb(32, 32, 32);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.vm-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 15px;
  min-height: 75px;
}

.vm-info-subcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 15px;
}

.vm-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 768px) {
  .vm-media-name {
    width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.vm-download-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(53, 53, 53);
  border-width: 0px;
  color: white;
  margin-right: 15px;
  outline: none;
  border-radius: 2px;
  padding: 5px 15px 5px 15px;
}

.vm-download-btn:focus {
  outline: none;
}

.vm-add-btn {
  background-color: rgb(64, 145, 213);
  border-width: 0px;
  color: white;
  outline: none;
  border-radius: 2px;
  padding: 5px 15px 5px 15px;
}

.vm-add-btn:focus {
  outline: none;
}

.definition-btn {
  display: flex;
  flex-direction: 'row';
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 30px;
  height: 25px;
  border-radius: 2px;
  background-color: rgb(43, 43, 43);
  color: white;
}

.definition-btn-active {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 30px;
  height: 25px;
  border-radius: 2px;
  background-color: white;
  color: rgb(43, 43, 43);
}

.vm-image {
  width: 400px;
  height: 400px;
  object-fit: contain;
}

.vm-thumbnail {
  width: 50px;
}

.vm-left-arrow:hover {
  cursor: pointer;
}

.vm-left-arrow {
  margin-left: 5px;
  font-size: 50px;
  color: white;
}

.vm-right-arrow:hover {
  cursor: pointer;
}

.vm-right-arrow {
  margin-right: 5px;
  font-size: 50px;
  color: white;
}

.vm-close {
  align-self: flex-end;
  margin-right: 5px;
  font-size: 30px;
  color: white;
}

.vm-close:hover {
  opacity: 0.3;
  cursor: pointer;
}

.vm-image-and-carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.vm-carousel-container {
  margin-top: 30px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.vm-mainimage {
  width: 80%;
  height: 526px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.vm-video {
  width: 80%;
  height: 526px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.pbv-hroot-container {
  display: flex;
  flex-flow: row wrap;
  opacity: 1;
  /* width: 100%; */
  overflow: hidden;
  height: 280px;
}

.pbv-hbutton-subcontainer {
  margin: 0px 20px 38px 0px;
  width: 220px;
}

.pbv-hbutton {
  height: 220px;
  width: 220px;
  border: none;
  outline: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  /* background-color: var(--global-color-3); */
  background-color: white;
  margin-bottom: 11px;
  /* background-image: url('../../images/no-image.png'); */
}

.pbv-hmedia-name {
  width: 220px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
  text-transform: uppercase;
}

.pbv-hsubtitle {
  color: #b6b6b6;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  margin: 0;
}

.pbv-hstar {
  margin-top: 5px;
  margin-right: 5px;
  float: right;
  color: rgb(217, 165, 31);
  font-size: 20;
}

.pbv-hstar:hover {
  color: rgb(196, 33, 70);
}

.pbv-hstarv2 {
  margin-top: 5px;
  margin-right: 5px;
  float: right;
  color: rgb(165, 181, 198);
  font-size: 20;
}

.pbv-hstarv2:hover {
  color: rgb(64, 145, 213);
}

.pbv-root-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  opacity: 1;
  width: 100%;
}

.pbv-linktype-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  /* margin-top: 20px; */
  /* opacity: 1; */
}

.pbv-linktype-heading {
  font-size: 25px;
  color: white;
}

.pbv-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.pbv-button-container-rm {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.pbv-button-subcontainer {
  margin: 0px 10px 38px 10px;
  width: 220px;
}

.pbv-button-subcontainer-rm {
  margin: 0px 18px 38px 18px;
  width: 220px;
}

.pbv-button {
  height: 220px;
  width: 100%;
  border: none;
  outline: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  /* background-color: var(--global-color-3); */
  /* background-color: white; */
  margin-bottom: 11px;
  /* background-image: url('../../images/no-image.png'); */
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.pbv-star {
  margin-top: 5px;
  margin-right: 5px;
  float: right;
  color: rgb(217, 165, 31);
  font-size: 20;
}

.pbv-star:hover {
  color: rgb(196, 33, 70);
}

.pbv-starv2 {
  margin-top: 5px;
  margin-right: 5px;
  float: right;
  color: rgb(165, 181, 198);
  font-size: 20;
  z-index: 999;
}

.pbv-starv2:hover {
  color: rgb(64, 145, 213);
}

.blocCheckIcon {
  font-size: 50px;
  color: white;
}

@keyframes custom-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.75;
  }
}

.pbv-selected {
  width: 100%;
  height: 100%;
  opacity: 0.75 !important;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: custom-fadein;
  animation-duration: 1s;
}

.pbv-btn-selected {
  width: 97%;
  height: 205px;
}

.pbv-media-name {
  width: 220px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 12px;
}

@media only screen and (max-width: 1200px) {
  .pbv-button {
    /* height: 250px;
    width: 250px; */
  }

  .pbv-media-name {
    /* width: 200px; */
  }

  /* .pbv-media-name {
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  } */
}

@media only screen and (max-width: 850px) {
  .pbv-button-subcontainer {
    margin: 0px 15px 38px 15px;
    width: 150px;
  }

  .pbv-button {
    height: 150px;
    width: 150px;
  }

  .pbv-media-name {
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.pbv-subtitle {
  color: #b6b6b6;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  margin: 0;
}

.pbv-linktype-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.pbv-caretdown {
  color: white;
  font-size: 60px;
  margin-left: 10px;
  cursor: pointer;
}

.pbv-favoris-tooltip {
  margin-left: 138px;
}

@media only screen and (max-width: 850px) {
  .pbv-favoris-tooltip {
    margin-left: 70px;
  }
}

.pbv-select-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
}

.pbv-root-container-rm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  opacity: 1;
  width: 100%;
  min-height: 500px;
}

.pbv-raccourcis-container {
  display: none;
  background-color: white;
  padding: 0px 0px 3px 3px;
}

.pbv-button:hover .pbv-raccourcis-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.pbv-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: black;
  font-size: 12px;
  line-height: 12px;
  margin-top: 5px;
}

.Toastify__toast--default {
  color: black;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Noto Sans';
}

.plv-star:hover {
  cursor: pointer;
}

.plv-root-container {
  display: flex;
  flex-direction: column;
  opacity: 1;
  align-items: center;
}

.plv-linktype-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  /* margin-top: 20px; */
  /* opacity: 1; */
}

.plv-linktype-heading {
  font-size: 25px;
  color: white;
  margin-top: 20px;
  margin-bottom: 30px;
}

.plv-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.plv-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  /* margin-bottom: 20px; */
  width: 100%;
}

.plv-search-container {
  display: flex;
  height: 75px;
  width: 100%;
}

.plv-search-subcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
}

.plv-search-subcontainer:hover {
  text-decoration: none;
}

.plv-el-root-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.plv-subcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.plv-subcontainer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.plv-subcontainer-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.plv-subcontainer-content {
  margin-left: 30px;
  width: 50%;
}

.plv-subcontainer-content-id {
  padding: 0 22px 0 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
  color: #b6b6b6;
}

.plv-subcontainer-content-type {
  font-weight: 700;
  font-size: 11px;
  line-height: 11px;
  display: inline-block;
  padding: 5px 7px;
  color: #646464;
  background: #fff;
  border-radius: 4px;
  margin-right: 20px;
}

.plv-subcontainer-container {
  width: 100%;
}

.plv-subcontainer-content-title {
  font-size: 13px;
  line-height: 13px;
  color: #232323;
  font-weight: 600;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.plv-subcontainer-content-subtitle-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.plv-subcontainer-content-subtitle {
  color: #b6b6b6;
  font-weight: 500;
  font-size: 12px;
  margin: 0;
  white-space: nowrap;
}

.plv-star-container {
  align-self: flex-start;
  margin-right: 10px;
}

.plv-star-active {
  cursor: pointer;
  color: rgb(217, 165, 31);
  font-size: 16px;
}

.plv-star-active:hover {
  color: rgb(196, 33, 70);
}

.plv-star {
  color: rgb(165, 181, 198);
  font-size: 16px;
}

.plv-star:hover {
  color: rgb(64, 145, 213);
}

.plv-button {
  height: 100px;
  width: 100px;
  border: none;
  outline: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* background-color: var(--global-color-3); */
}

.plv-search-button {
  height: 75px;
  width: 75px;
  min-width: 75px;
  border: none;
  outline: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.listCheckIcon {
  font-size: 50px;
  color: white;
}

@keyframes custom-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.75;
  }
}

.plv-selected {
  width: 100%;
  height: 100%;
  opacity: 0.75 !important;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: custom-fadein;
  animation-duration: 1s;
}

/* .plv-selected {
  width: 100%;
  height: 100%;
  opacity: 0.5;
} */

@media only screen and (max-width: 1024px) {
  .plv-container {
    height: 100px;
  }

  .plv-root-container .plv-button {
    height: 100px;
    width: 150px;
    min-width: 150px;
  }
}

.plv-cart-remove {
  color: black;
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.plv-linktype-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.plv-caretdown {
  color: white;
  font-size: 60px;
  margin-left: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 850px) {
  .plv-subcontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
  }

  .plv-subcontainer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
  }
}

.plv-icons-container {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.plv-right-search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plv-select-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
}

.plv-root-container-rm {
  display: flex;
  flex-direction: column;
  opacity: 1;
  justify-content: flex-start;
  align-items: center;
  min-height: 500px;
}

.plv-raccourcis-container {
  display: none;
}

.plv-container:hover .plv-raccourcis-container {
  align-self: flex-start;
  display: flex;
}

.plv-search-container:hover .plv-raccourcis-container {
  align-self: flex-start;
  display: flex;
}

.plv-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: black;
  font-size: 12px;
  line-height: 12px;
  margin-top: 5px;
}

.tvsp-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  width: 100%;
}

.tvsp-path {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 140px;
  font-size: 14px;
  line-height: 18px;
  flex-wrap: wrap;
  width: 100%;
}

@media only screen and (max-width: 850px) {
  .tvsp-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    width: 800%;
  }

  .tvsp-path {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 18px;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-left: 0px;
  }
}

.tvsp-button {
  margin-right: 200px;
}

.tvsp-el {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tvsp-el-button {
  background-color: transparent;
  border-width: 0;
  font-size: 14px;
  white-space: nowrap;
  text-align: center;
}

.tvsp-el-button:focus {
  outline: none;
}

.tvsp-el-arrow {
  margin-left: 5px;
  margin-right: 5px;
  color: black;
}

.tvsp-product-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border-width: 0;
  font-size: 14px;
  white-space: nowrap;
  text-align: center;
  color: black;
  margin-left: 8px;
}

.tvsp-end {
  height: 10px;
  width: 70%;
}

.tvsp-retour {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #ebeef0;
  border-radius: 3px;
  padding: 0px 42px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  cursor: pointer;
  box-shadow: 0px 10px 40px 0px rgba(18, 32, 118, 0.08);
  background-color: var(--global-color-1);
}

.tvsp-retour:hover {
  background-color: var(--global-color-2);
}

.tvsp-retour-txt {
  white-space: nowrap;
}

.ci-container {
  width: 100%;
  height: 150px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ci-img {
  background-repeat: no-repeat;
  background-size: contain;
  width: 140px;
  height: 140px;
  background-position: center center;
  margin-left: 30px;
}

.ci-download-btn {
  margin-right: 20px;
  cursor: pointer;
}

.ci-trash {
  cursor: pointer;
  margin-right: 20px;
  font-size: 14px;
}

.ic-bloc {
  margin-left: 20px;
  margin-right: 10px;
  color: black;
}

.ic-bloc:hover {
  cursor: pointer;
}

.ic-list {
  color: black;
}

.ic-list:hover {
  cursor: pointer;
}

.ic-bloc-rm {
  margin-left: 20px;
  margin-right: 10px;
  color: white;
}

.ic-bloc-rm:hover {
  cursor: pointer;
}

.ic-list-rm {
  color: white;
}

.ic-list-rm:hover {
  cursor: pointer;
}

.ic-container {
  width: 100%;
}

.ic-subcontainer {
  width: 100%;
}

.ic-subsubcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.ic-switchviewcontainer-container {
  margin-bottom: 20px;
}

.ic-switchviewcontainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

#ic-secondrow-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

input[type='checkbox'] {
  margin-right: 10px;
  width: auto;
}

.ic-label-check {
  display: inline-block;
  padding: 2% 9%;
  cursor: pointer;
  width: 100%;
}

.ic-secondrow-selection-selectionmode {
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(39, 39, 39);
  outline: none;
  border-width: 0px;
  border-radius: 3px;
  color: white;
  /* padding-left: 24px;
  padding-right: 24px; */
  height: 44px;
  font-size: 14px;
  font-weight: 600;
}

.ic-secondrow-showwhat-mediastypes {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 2px;
  margin-right: 20px;
  width: 350px;
  justify-content: center;
}

.ic-secondrow-selection-allselection {
  width: 200px;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(39, 39, 39);
  outline: none;
  border: 1px solid black;
  border-radius: 3px;
  background-color: white;
  color: black;
  margin-left: 5px;
  height: 44px;
}

.ic-secondrow-selection-allselection-hide {
  display: none;
}

.ic-footer-hide {
  display: none;
}

.ic-footerstyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(39, 39, 39);
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  position: fixed;
  bottom: 10px;
  height: 60px;
  width: 1300px;
  max-width: 90%;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  font-size: 14px;
}

@media only screen and (max-width: 850px) {
  .ic-footerstyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(39, 39, 39);
    border-radius: 4px;
    text-align: center;
    vertical-align: middle;
    position: fixed;
    bottom: 10px;
    height: 60px;
    width: 1024px;
    max-width: 90%;
    left: 50%;
    transform: translate(-50%, 0);
    color: white;
    font-size: 14px;
  }
}

.ic-footerstyle-p {
  margin-left: 50px;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.375rem;
}

.ic-footerstyle-b:focus {
  outline: none;
}

.ic-footerstyle-b:hover {
  background-color: var(--global-color-2);
}

.ic-footerstyle-b {
  cursor: pointer;
  margin-right: 20px;
  background: #3d3d3d;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  line-height: 1rem;
  font-size: 1rem;
  padding: 0 30px 0 30px;
  height: 44px;
  line-height: 43px;
}

#ic-check {
  cursor: pointer;
}

.ic-noresult-container {
  background-color: rgb(230, 233, 237);
  width: 100%;
  height: 100%;
  padding: 4rem 2rem;
  color: black;
}

.ic-noresult-h1 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.ic-noresult-p {
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 1rem;
}

.ic-noresult-btn {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ic-noresult-btn:hover {
  background-color: rgb(165, 181, 198);
}

.ic-loader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
}

.ic-view-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

@media only screen and (max-width: 1200px) {
  .ic-subsubcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ic-switchviewcontainer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ic-bloc {
    margin-left: 0px;
    margin-right: 10px;
    color: black;
  }

  .ic-bloc-rm {
    margin-left: 0px;
    margin-right: 10px;
    color: white;
  }

  .ic-secondrow-showwhat-mediastypes {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border-radius: 2px;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 350px;
    justify-content: center;
  }

  .ic-view-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 20px;
  }
}

.ic-itemview {
  width: 100%;
}

.ic-cart-download {
  width: 200px;
  height: 50px;
  background-color: black;
  color: white;
  outline: none;
  border: none;
}

.ic-cart-empty {
  width: 200px;
  height: 50px;
  background-color: #e9e9e9;
  color: black;
  outline: none;
  border: none;
  margin-left: 10px;
}

@media only screen and (min-width: 1280px) {
  .search-input-container {
    display: none;
  }

  .search-input {
    display: none;
  }
}

@media only screen and (max-width: 1280px) {
  .search-input-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .search-input-subcontainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 1300px;
    max-width: 90%;
  }

  .search-input {
    display: block;
    width: 300px;
    margin-right: 20px;
  }
}

.search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-subcontainer {
  width: 1500px;
  max-width: 90%;
}

.search-heading {
  color: #b6b6b6;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25rem;
  vertical-align: middle;
}

.search-heading-b {
  color: rgb(32, 32, 32);
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25rem;
  vertical-align: middle;
}

.search-heading-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.search-type-heading {
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 700;
  color: #b6b6b6;
  font-size: 11px;
  line-height: 11px;
  margin-bottom: 15px;
}

.search-divider {
  margin-left: 5px;
}

.search-pagination-innerClass {
  margin-top: 75px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  height: 30px;
}

.search-pagination-itemClass {
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(217, 222, 226);
  cursor: pointer;
}

.search-pagination-activeClass {
  background-color: rgb(75, 75, 75);
  border: 1px solid rgb(75, 75, 75);
}

.search-pagination-itemClass:hover {
  background-color: rgb(230, 233, 237);
}

.search-pagination-activeClass:hover {
  background-color: rgb(75, 75, 75);
}

.search-pagination-itemClassFirst {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.search-pagination-itemClassPrev {
  width: auto;
}

.search-pagination-itemClassNext {
  width: auto;
}

.search-pagination-itemClassLast {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.search-pagination-linkClass {
  color: black;
}

.search-pagination-activeLinkClass {
  color: white;
}

.search-pagination-itemClass:hover > .search-pagination-linkClass {
  text-decoration: none;
  color: rgb(0, 76, 170);
}

.search-pagination-activeClass:hover > .search-pagination-activeLinkClass {
  text-decoration: none;
  color: white;
}

.search-pagination-linkClassFirst {
}

.search-pagination-linkClassPrev {
  margin-left: 10px;
  margin-right: 10px;
}

.search-pagination-linkClassNext {
  margin-left: 10px;
  margin-right: 10px;
}

.search-pagination-linkClassLast {
}

.search-pagination-disabledClass:hover {
  background-color: white;
  cursor: default;
}

.search-pagination-disabledClass > .search-pagination-linkClassFirst {
  color: rgb(95, 104, 112);
  cursor: default;
}

.search-pagination-disabledClass > .search-pagination-linkClassFirst:hover {
  color: rgb(95, 104, 112);
  cursor: default;
}

.search-pagination-disabledClass:hover > .search-pagination-linkClassFirst {
  color: rgb(95, 104, 112);
}

.search-pagination-disabledClass > .search-pagination-linkClassPrev {
  color: rgb(95, 104, 112);
  cursor: default;
}

.search-pagination-disabledClass > .search-pagination-linkClassPrev:hover {
  color: rgb(95, 104, 112);
  cursor: default;
}

.search-pagination-disabledClass:hover > .search-pagination-linkClassPrev {
  color: rgb(95, 104, 112);
}

.search-pagination-disabledClass:hover > .search-pagination-linkClassLast {
  color: rgb(95, 104, 112);
}

.search-pagination-disabledClass > .search-pagination-linkClassLast {
  color: rgb(95, 104, 112);
  cursor: default;
}

.search-pagination-disabledClass > .search-pagination-linkClassLast:hover {
  color: rgb(95, 104, 112);
  cursor: default;
}

.search-pagination-disabledClass:hover > .search-pagination-linkClassLast {
  color: rgb(95, 104, 112);
}

.search-pagination-disabledClass > .search-pagination-linkClassNext {
  color: rgb(95, 104, 112);
  cursor: default;
}

.search-pagination-disabledClass > .search-pagination-linkClassNext:hover {
  color: rgb(95, 104, 112);
  cursor: default;
}

.search-pagination-disabledClass:hover > .search-pagination-linkClassNext {
  color: rgb(95, 104, 112);
}

.search-btn {
  margin-top: 22px;
  color: white;
  max-width: 100%;
  background-color: var(--global-color-1);
  border: 1px solid transparent;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  padding: 0 42px 0 42px;
  height: 44px;
  border-radius: 0.25rem;
  margin-bottom: 44px;
}

.search-btn:hover {
  background-color: var(--global-color-2);
}

.search-btn-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.search-retour {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #ebeef0;
  border-radius: 3px;
  background-color: var(--global-color-1);
  padding: 0px 42px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  cursor: pointer;
  box-shadow: 0px 10px 40px 0px rgba(18, 32, 118, 0.08);
  margin-right: 38px;
}

.search-retour:hover {
  background-color: var(--global-color-2);
}

.search-retour-txt {
  white-space: nowrap;
}

.search-loader {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
}

.mmic-container {
  margin-top: 30px;
  width: 470px;
  max-width: 75%;
}

.mmic-nextimage:hover {
  cursor: pointer;
}

.mmic-previousimage:hover {
  cursor: pointer;
}

.mmic-arrow {
  cursor: pointer;
  width: 30px;
  height: 46px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mmic-carouselimg-active {
  max-width: 72px;
  max-height: 46px;
}

.mmic-carouselimg {
  max-width: 72px;
  max-height: 46px;
  opacity: 0.5;
}

@media only screen and (min-width: 850px) {
  .bi-container {
    width: 1024px;
    max-width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 48px;
    padding-bottom: 66px;
  }

  .bi-elts-container {
    display: block;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
    width: 49%;
    margin: 0 0 20px 0;
    padding: 26px 20px;
    background: #f4f7f9;
    border-radius: 4px;
  }
}

@media only screen and (max-width: 850px) {
  .bi-container {
    width: 1024px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 48px;
    padding-bottom: 66px;
  }

  .bi-elts-container {
    display: block;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
    width: 90%;
    margin: 0 0 20px 0;
    padding: 26px 20px;
    background: #f4f7f9;
    border-radius: 4px;
  }
}

.bi-title {
  letter-spacing: -0.5px;
  margin: 0 0 14px 0;
  color: #6b7c8d;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
}

.bi-content {
  letter-spacing: -0.5px;
  margin: 0;
  color: #232323;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.25rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.parameters-menuelement {
  color: #007bff !important;
  cursor: pointer;
}

.parameters-menuelement:hover {
  text-decoration: underline !important;
}

.sf-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.sf-element {
  margin-top: 10px;
  margin-right: 20px;
}

.sf-date-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sf-heading {
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 700;
  color: #b6b6b6;
  font-size: 11px;
  line-height: 11px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.sf-subheading {
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 700;
  color: #b6b6b6;
  font-size: 8px;
  line-height: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.react-datepicker-wrapper {
  width: 125px;
}

.sf-submit-btn {
  margin-top: 30px;
  height: 40px;
  width: 150px;
  border: none;
  color: white;
  background-color: black;
  border-color: black;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  display: flex;
}

.react-datepicker__input-container {
  font-size: 14px;
}

/* MULTI SELECT */

.dropdown {
  width: 225px;
}

.dropdown-heading {
  height: 40px !important;
}

.dropdown-heading-value {
  font-size: 14px;
  line-height: 38px !important;
}

.dropdown-content {
  font-size: 14px;
}

.item-renderer span {
  vertical-align: bottom !important;
}

.heading {
  margin-top: 50px;
  margin-left: 50px;
}

.tousfavoris {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  float: right;
  outline: none;
  border: none;
  text-decoration: none;
  color: black;
  font-size: 14px;
  width: 200px;
  /* margin-bottom: 80px; */
}

.tousproduits {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  float: right;
  outline: none;
  border: none;
  text-decoration: none;
  color: black;
  font-size: 14px;
  width: 200px;
}

.tousproduits:hover {
  text-decoration: none;
  cursor: pointer;
  color: var(--global-color-2);
}

.homepage-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.homepage-subcontainer {
  max-width: 1500px;
  width: 100%;
}

.homepage-headingtxt {
  vertical-align: middle;
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: 600;
  white-space: nowrap;
}

.homepage-buttonscontainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
}

.CONTAINER {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 50px;
  margin-right: 50px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 700px) {
  .CONTAINER {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 50px;
    margin-right: 50px;
    padding-bottom: 20px;
  }
}

.slidercontainer {
  display: flex;
  justify-content: center;
}

.awesomeslider {
  margin-top: 1px;
  /* height: 600px; */
}

.sliderinfoa {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.sliderinfobtn {
  position: absolute;
  right: 10%;
  bottom: 20%;
  color: white;
  width: 18%;
  height: 8%;
  background-color: black;
  border-color: black;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  display: flex;
}

.sliderinfobtn:hover {
  cursor: pointer;
}

.sliderinfobtn:focus {
  outline: none;
}

@media only screen and (max-width: 850px) {
  .sliderinfobtntxt {
    font-size: 12px;
    white-space: nowrap;
  }

  .sliderinfobtn {
    height: 8%;
    width: 20%;
  }
}

@media only screen and (min-width: 850px) {
  .sliderinfobtntxt {
    font-size: 12px;
  }
}

.misesajourcontainer {
  display: flex;
  flex-direction: column;
}

.misesajourtitle {
  margin-top: 50px;
  margin-bottom: 15px;
  text-transform: uppercase;
  margin-left: 50px;
}

.blocvignettecontent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 50px;
  /* height: 330px;
  width: 330px; */
}

.blocvignettecontenta {
  height: 100%;
  width: 100%;
}

.blocvignettecontenta:hover {
  text-decoration: none;
}

.blocvignettecontentimage {
  width: 100%;
  height: auto;
  /* max-width: 300px; */
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1200px) {
  .blocvignettecontenttitle {
    color: black;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
  }
}

@media only screen and (min-width: 1200px) {
  .blocvignettecontenttitle {
    color: black;
    font-size: 13px;
    line-height: 1;
    font-weight: 600;
  }
}

.awssld__bullets {
  z-index: 2;
  bottom: 0px;
}

.awssld__bullets button {
  width: 8px;
  height: 8px;
  background-color: white;
  opacity: 0.5;
}

.awssld__bullets .awssld__bullets--active {
  background-color: white;
  opacity: 1;
}

.awesomeslider .awssld__timer {
  opacity: 0;
  pointer-events: none;
}

.awssld {
  --loader-bar-color: transparent;
}

.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footer-subcontainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  max-width: 1500px;
  width: 90%;
}

.footer {
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
  color: #c3c3c3;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 3px;
  font-size: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  /* margin-right: 50px; */
}

.footer span {
  margin-right: 10px;
}

.imgFooter {
  background-image: url(/static/media/logoQuable.cb55372f.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 20px;
  margin-left: 1%;
}

.produits-innerview {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.produits-outercontainer {
  width: 100%;
}
.produits-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.produits-subcontainer {
  max-width: 1300px;
  width: 90%;
}

.medias-innerview {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.medias-outercontainer {
  width: 100%;
}

.medias-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.medias-subcontainer {
  max-width: 1300px;
  width: 90%;
}

.favoris-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.favoris-subcontainer {
  max-width: 1500px;
  width: 90%;
}

.switchviewcontainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.favorisbloc {
  margin-right: 10px;
}

.favorisbloc:hover {
  cursor: pointer;
}

.favorislist:hover {
  cursor: pointer;
}

.favoris-noresult-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.favoris-noresult-subcontainer {
  background-color: rgb(230, 233, 237);
  padding: 4rem 2rem;
  color: black;
  max-width: 1500px;
  width: 85%;
}

.favoris-noresult-h1 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.favoris-noresult-p {
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 1rem;
}

.favoris-noresult-btn {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.favoris-noresult-btn:hover {
  background-color: rgb(165, 181, 198);
  text-decoration: none;
  color: #fff;
}

.cart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.cart-subcontainer {
  max-width: 1500px;
  width: 85%;
}

.cart-downloadall {
  width: 150px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  border-radius: 20px;
  border-width: 0px;
  background-color: rgb(39, 116, 169);
}

.cart-downloadall:focus {
  outline: none;
}

.cart-title {
  text-align: center;
  margin: 20px;
  font-size: 20px;
}

.cart-itemcontainer::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.cart-itemcontainer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.cart-noresult-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.cart-noresult-subcontainer {
  background-color: rgb(230, 233, 237);
  padding: 4rem 2rem;
  color: black;
  max-width: 1500px;
  width: 85%;
}

.cart-noresult-h1 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.cart-noresult-p {
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 1rem;
}

.cart-noresult-btn {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.cart-noresult-btn:hover {
  background-color: rgb(165, 181, 198);
  text-decoration: none;
  color: #fff;
}

.infotable {
  margin-bottom: 30px;
  width: 100%;
}

.inforow0 {
  background-color: rgb(242, 246, 248);
}

.inforow1 {
  background-color: white;
}

.inforowelement1 {
  font-size: 13px;
  width: 40%;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  line-height: initial;
  font-weight: 600;
}

.inforowelement2 {
  font-size: 13px;
  height: 50px;
  width: 60%;
  text-align: center;
  vertical-align: middle;
  line-height: initial;
  font-weight: 600;
}

.productpage-path-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.productpage-path {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 28px 0px;
  width: 90%;
  max-width: 1500px;
}

.productpage-loader-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
}

.productpage-loader-msg {
  margin-bottom: 20px;
}

.login-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.login-subcontainer {
  padding: 66px 56px;
  text-align: center;
  position: relative;
  margin: 0 auto 0 auto;
  height: auto;
  width: 372px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 30px -4px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-logo {
  margin-bottom: 32px;
  width: 80%;
  height: auto;
  max-width: 182px;
}

.login-input {
  margin: 0 0 18px 0;
  padding: 16px 20px 17px 20px;
  border: 1px solid #e5e5e5;
  border-bottom: 2px solid #e5e5e5;
  border-radius: 4px;
  width: 100%;
  display: block;
  font-weight: 600;
  line-height: 14px;
  font-size: 14px;
  color: #000;
}

.login-checkbox-label {
  color: #222;
  -webkit-user-select: none;
          user-select: none;
  padding-left: 8px;
  display: inline-block;
  margin-bottom: 20px;
  font-size: 13px;
  line-height: 13px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.login-button {
  padding: 16px 20px 17px 20px;
  display: block;
  color: #fff;
  background: #000;
  font-weight: 600;
  line-height: 1rem;
  font-size: 1rem;
  width: 100%;
  text-align: center;
  border: 0;
  border-radius: 4px;
  font: 600 13.3333px Arial;
}

.parametres-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.parametres-subcontainer {
  width: 85%;
  max-width: 1500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.parametres-leftmenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

h1 {
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

.parametres-leftmenu-element {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  margin-left: 20px;
}

.parametres-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 100px;
}

.parametres-right-firstrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#parametres-pagesize {
  font-size: 12px;
  height: 30px;
  border: 1px solid grey;
}

#filter-text-box {
  font-size: 12px;
  height: 30px;
}

.parametres-righttxt {
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
}

.parametres-right-firstrow-firsttxt {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.parametres-table-btn1 {
  margin-right: 20px;
  color: rgb(39, 116, 169);
}

.parametres-table-btn2 {
  margin-right: 20px;
  color: red;
}

.parametres-icon {
  margin-right: 5px;
}

.parametre-popup {
  width: 250px;
  border-radius: 25px;
  border: 2px solid black;
}

/** Gestion du tableau de recherche **/

.grid-search-box {
  height: 35px;
  width: 100%;
  border-width: 1px;
  border-radius: 20px;
  padding: 0.25rem 3rem 0.25rem 3rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAzVJREFUeNp0U1tIk2EYfnf2jAc8o3MHRTfdShHBqZRYat4Y7MLFkkIvgqWQCN7kjTeBF10IWVceIhCKSIWCjEARMRNdG4o6nPOwubnpnA7xsOl6vkFe2Qcf+/53//e8z+H9OQcHB+R2u2loaIj29vaKPR6P1mw238U5KTs721VaWvrt8PDwY21t7UZZWRnl5eXR5eUlXa+TkxNaWloS6HS6F/Hx8X6UQmzHxcWF/p0zMzMdbW1tj4xGI6EBa3S9+VarlTM+Pv58ZGTkNQMsLCyckUqlY+jijo2NFc/Ozmq3traK+vv7B5RKJTc/P/+D3+8nDocTJsBTqVTqnp6e4fPzc5Fer39XU1NjyMrKmoA0U0NDw1RlZeVEMBiUWCwWxfLyshrPo1FRUUenp6d0cXFB3Lm5uaajo6O48vLymZaWlpehUMjFOjCdzJ+MjAxrb2/vs/T0dLvNZpONjY09FAgEFAgEwpsHg14dHx9nNjY29pWUlPyED+T1eml/f59gYhgoNTXVv7q6qgCLYnT1AeAzjCbUiA8jEvh8fig6OtrF4/HCugBIMI4ATk6nk3Z2digtLW2D/WcymeJ3d3fp6uoq/C5fLBbvra2tyVHMAU2CqTQ5OUkSiYTgC3V1dZFMJiOkpGQXKioqPO3t7cRkhgFg2lcAaObn53WgPRgZGbkrFAqJy+USWBHqDFSxuLj4AEwDeH8KINcAXCB/QmfnysqKsrOz863L5ZIyk1hMIpGI5X6ru7t7GOAJiPFPfX39iN1uJ5/PF5bIYQfMgN5gMAxCFz8xMdEhl8u/FxQU2KBXAefvI6Uk1g2mrmu12iZ4tMASYIbzOjo6WCcz6NkQmwoJ5Gxvb9/G5WqwKUKswtzcXCNYBR0Oh2Rzc7Ma0c7DHzuTyQNtwqRRcnKyWa1Wf8F3sZWSkuLFiNs1Gs0Mon3T3NzchdoPXL4HUBmo3wGb3zExMQ4uo8b0Ytro7OzMDhl9dXV1esTW0Nra+hQfzwAuHKO+CAN16L4OJvLp6en3uFfGpRsWG1M2QMxpppU1YLnj93dVVdVjzIgFTPJGR0eHbgT432IsAfIL38MTMFmIiIjw/BVgAKbVxB1zTtOxAAAAAElFTkSuQmCC) no-repeat 12px center;
  background-color: white;
  outline: medium none;
  font-size: 14px;
  vertical-align: middle;
}

.row_bottom_margin {
  margin-bottom: 15px;
}

.page-size-select {
  width: 45px;
  outline: none;
  box-shadow: none;
  margin-right: 10px;
  margin-left: 10px;
}
.page-size-select:focus {
  width: 35px;
  outline: none;
  box-shadow: none;
}

.page-size-select-row {
  text-align: right;
  margin: 10px;
}

.action-button {
  cursor: pointer;
}

@media only screen and (max-width: 850px) {
  .parametres-subcontainer {
    width: 85%;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .parametres-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 0px;
    margin-top: 50px;
  }
}

.parametres-prismic {
  color: #516677;
  font-size: 14px;
  font-weight: 600;
}

.parametres-prismic:hover {
  text-decoration: none;
  cursor: pointer;
  color: #516677;
}

.mediapage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mediapage-path {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 28px 0px;
  width: 85%;
  max-width: 1500px;
}

.mediapage-carouselcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: black;
  border-top: 1px solid #e9eff5;
  border-bottom: 1px solid #e9eff5;
  width: 100%;

  padding: 36px 0;
}

.mediapage-mainimage {
  width: 80%;
  height: 526px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.mediapage-video {
  width: 700px;
  height: 526px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.video-react .video-react-big-play-button {
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.mediapage-name {
  margin: 0 0 10px 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: #fff;
}

.mediapage-date {
  padding: 0 10px;
  margin: 0;
  display: inline-block;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  color: #b6b6b6;
}

.mediapage-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

.mediapage-download-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(53, 53, 53);
  border-width: 0px;
  color: white;
  margin-right: 15px;
  outline: none;
  border-radius: 2px;
  padding: 5px 15px 5px 15px;
}

.mediapage-download-btn:focus {
  outline: none;
}

.mediapage-download-a {
  padding-left: 20px;
  padding-right: 20px;
}

.mediapage-add-btn {
  background-color: rgb(64, 145, 213);
  border-width: 0px;
  color: white;
  outline: none;
  border-radius: 2px;
  padding: 5px 15px 5px 15px;
}

.mediapage-add-btn:focus {
  outline: none;
}

.mediapage-fullinfo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 15px;
  min-height: 75px;
}

.mediapage-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 15px;
}

.mediapage-cart-icon {
  font-size: 20px;
  margin-right: 5px;
}

.denied_header{
    position:absolute;
    top:30px;
    left:30px;
}

.denied_msg{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    align-items: center
}


.denied-footerstyle-b:focus {
    outline: none;
}

.denied-footerstyle-b:hover {
    background-color: #3d3d3d;
}

.denied-footerstyle-b {
    cursor: pointer;
    margin-right: 20px;
    background: #3d3d3d;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    line-height: 1rem;
    font-size: 1rem;
    padding: 0 30px 0 30px;
    height: 44px;
    line-height: 43px;
}

.denied_btn-container{
    margin-top: 100px;
}
body {
  margin: 0;
  padding: 0;
  /* font-family: 'Open Sans', sans-serif; */
  font-family: 'Noto Sans';
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}

html,
body,
#root,
#root > div {
  height: 100%;
}

