.home-subheader {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e9e9e9;
  position: relative;
}

.sh-img-bandeau {
  position: relative;
  background-repeat: no-repeat !important;
  background-position: right 70% !important;
  background-size: cover !important;
  height: 200px;
  padding: 42px 0 0 0;
  line-height: 200px;
  margin: 0 0 0 0;
  border-bottom: 1px solid #e9e9e9;
}

.sh-subcontainer {
  /* min-height: 66px; */
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  background-repeat: no-repeat !important;
  background-position: right 70% !important;
  background-size: cover !important;
  /* height: inherit; */
  /* background: linear-gradient(to left, rgba(0,0,0,0), rgba(255, 0, 255, 0.95), rgba(255, 255, 255, 1)); */
  position: relative;
}

.sh-subsubcontainer {
  width: 90%;
  max-width: 1500px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.retour {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-color: lightgrey;
  border-radius: 4px;
  border-style: solid;
  background-color: var(--global-color-1);
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
  color: white;
  box-shadow: 0px 10px 40px 0px rgba(18, 32, 118, 0.08);
  height: 44px;
}

.retour:hover {
  background-color: var(--global-color-2);
}

.icon {
  margin-right: 10px;
}

.subheader-title {
  font-size: 16px;
  color: black;
  font-weight: bold;
}
