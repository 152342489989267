.rm-container {
  /* margin-top: 50px; */
  background-color: rgb(32, 32, 32);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.rm-subcontainer {
  display: flex;
  flex-direction: column;
  width: 1024px;
  max-width: 90%;
}

.rm-firstrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 86px;
  margin-bottom: 20px;
}

.rm-firstrow-title {
  color: rgb(109, 109, 109);
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  letter-spacing: 3px;
}

.rm-firstrow-link {
  background-color: rgb(22, 22, 22);
  border-width: 0px;
  /* width: 250px; */
  border-radius: 3px;
  color: white;
  outline: none;
  text-decoration: none;
  text-align: center;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
  padding: 0 42px 0 42px;
  height: 44px;
  line-height: 43px;
}

.rm-firstrow-link:hover {
  outline: none;
  background-color: rgb(165, 181, 198);
}

@media only screen and (max-width: 1024px) {
  .rm-subcontainer {
    margin-left: 50px;
    margin-right: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .rm-subcontainer {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.rm-footer-hide {
  display: none;
}

.rm-footerstyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(39, 39, 39);
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  position: fixed;
  left: 0;
  bottom: 10px;
  height: 60px;
  width: 1024px;
  max-width: 90%;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  font-size: 14px;
}

.rm-footerstyle-p {
  margin-left: 50px;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.375rem;
}

.rm-footerstyle-b:focus {
  outline: none;
}

.rm-footerstyle-b:hover {
  background-color: var(--global-color-2);
}

.rm-footerstyle-b {
  cursor: pointer;
  margin-right: 20px;
  background: #3d3d3d;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  line-height: 1rem;
  font-size: 1rem;
  padding: 0 30px 0 30px;
  height: 44px;
  line-height: 43px;
}
