@import url('https://fonts.googleapis.com/css?family=Noto+Sans');
body {
  margin: 0;
  padding: 0;
  /* font-family: 'Open Sans', sans-serif; */
  font-family: 'Noto Sans';
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
}

html,
body,
#root,
#root > div {
  height: 100%;
}
