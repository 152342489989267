.iai-container {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* height: 550px; */
}

.iai-productpage-subcontainer {
  max-width: 1024px;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
}

.iai-productpage-image {
  width: 49%;
  min-width: 400px;
  height: 550px;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  /* background-image: url('../../../images/no-image.png'); */
}

.iai-fullinfocontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(230, 233, 237);
  width: 49%;
  min-width: 400px;
  /* height: 620px; */
}

.iai-infocontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5%;
}

.iai-infofavori-container {
  align-self: flex-end;
  margin-top: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
}

.iai-infofavori-active {
  cursor: pointer;
  color: rgb(217, 165, 31);
  font-size: 27px;
}

.iai-infofavori-active:hover {
  color: rgb(196, 33, 70);
}

.iai-infofavori {
  cursor: pointer;
  color: rgb(165, 181, 198);
  font-size: 27px;
}

.iai-infofavori:hover {
  color: rgb(64, 145, 213);
}

.iai-infotitle {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 500;
}

.iai-infocart-active {
  cursor: pointer;
  color: rgb(217, 165, 31);
  font-size: 27px;
}

.iai-infocart-active:hover {
  color: rgb(196, 33, 70);
}

.iai-infocart {
  cursor: pointer;
  color: rgb(165, 181, 198);
  font-size: 27px;
}

.iai-infocart:hover {
  color: rgb(64, 145, 213);
}

@media only screen and (max-width: 1200px) {
  .iai-productpage-subcontainer {
    max-width: 1024px;
    /* width: 90%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
  }

  .iai-productpage-image {
    width: 49%;
    min-width: 400px;
    height: 550px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    /* background-image: url('../../../images/no-image.png'); */
  }
}

.iai-productpage-loadingimage {
  width: 49%;
  min-width: 400px;
  height: 550px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
